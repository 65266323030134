import React, { useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  Stack,
  Chip,
  Typography,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { IoMdClose } from "react-icons/io";
import { FaPencil } from "react-icons/fa6";
import { putRequest } from "../../apiHandler";
import { toast } from "react-toastify";

export default function EditButton({
  searchData,
  item,
  getAlertData,
  alertId,
  setAlertId,
}) {
  const { alert_title, alert_type, email } = item;
  const CustomBox = styled(Box)(({ theme }) => ({
    background: "#F1F5FF",
    width: "36px",
    height: "36px",
    color: "#2f54eb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    cursor: "pointer",
    "&:hover": {
      width: "36px",
      height: "36px",
      border: "1px solid #2f54eb",
      background: "#2f54eb",
      color: "#F1F5FF",
    },
  }));

  const EditAlertClickOpen = (id) => {
    setAlertId(id);
    setOpen(true);
  };

  const [emailAlert, setEmailAlert] = useState({
    alert_title: alert_title,
    alert_type: alert_type,
    email: email,
  });
  const [open, setOpen] = useState(false);

  const editClickClose = () => {
    setOpen(false);
  };

  const handleChange = (e, newAlignment) => {
    const { name, value } = e.target;
    const updatedValues = newAlignment
      ? { alert_type: newAlignment }
      : { [name]: value };
    setEmailAlert((prevEmailAlert) => ({
      ...prevEmailAlert,
      ...updatedValues,
      email: email,
    }));
  };

  const updateAlert = async () => {
    try {
      if (alertId) {
        const response = await putRequest(
          `/api/update-users-alert/${alertId}`,
          emailAlert
        );
        if (response.status === 200) {
          getAlertData();
          editClickClose();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour des données."
      );
    }
  };

  return (
    <>
      <CustomBox
        onClick={() => {
          EditAlertClickOpen(item._id);
        }}
      >
        <FaPencil />
      </CustomBox>
      <Dialog
        onClose={editClickClose}
        aria-labelledby="CONTACT"
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
          "& .MuiPaper-root": {
            margin: 0.5,
            width: "98%",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            px: { xs: 2, sm: 4 },
            py: { xs: 3, sm: 5 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: 0.5, sm: 1.8 },
            background: "#d9d9d92b",
            border: "none",
            fontSize: { sm: "h6", md: "1.4rem !important" },
            fontWeight: "900",
            color: "#4d4a4a",
          }}
          id="CONTACT"
        >
          <FaPencil width={28} height={28} style={{ color: "#0066ee" }} />
          Modifier mon alerte
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={editClickClose}
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 15 },
            top: { xs: 0, sm: 46 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <DialogContent>
          <Box component="form" sx={{ px: { xs: 0, sm: 4 } }}>
            <Stack
              direction="row"
              sx={{ my: { xs: 0, sm: 2 }, gap: 1 }}
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              {searchData.ads_type && (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    background: "#F3F3F3",
                    color: "#4D4A4A",
                    fontWeight: "300",
                  }}
                  label={searchData.ads_type === "rent" ? "Louer" : "Vendre"}
                />
              )}
              {searchData?.property_type?.length > 0 && (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    background: "#F3F3F3",
                    color: "#4D4A4A",
                    fontWeight: "300",
                  }}
                  label={
                    Array.isArray(searchData?.property_type)
                      ? searchData.property_type.join(",")
                      : ""
                  }
                />
              )}
              {searchData?.areamin != 0 && searchData?.areamax != 0 && (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    background: "#F3F3F3",
                    color: "#4D4A4A",
                    fontWeight: "300",
                  }}
                  label={
                    searchData?.areamin + "-" + searchData?.areamax + " m²"
                  }
                />
              )}
              {searchData?.rooms?.length > 0 && (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    background: "#F3F3F3",
                    color: "#4D4A4A",
                    fontWeight: "300",
                  }}
                  label={
                    Array.isArray(searchData?.rooms)
                      ? searchData.rooms.join(",") + " Pièces"
                      : ""
                  }
                />
              )}
              {searchData?.pricemax != 0 && searchData?.pricemin != 0 && (
                <Chip
                  sx={{
                    borderRadius: "5px",
                    background: "#F3F3F3",
                    color: "#4D4A4A",
                    fontWeight: "300",
                  }}
                  label={
                    searchData.pricemin + "-" + searchData.pricemax + " MAD"
                  }
                />
              )}
            </Stack>
            <Typography variant="h2" sx={{ mt: { xs: 2, sm: 4 }, mb: 1 }}>
              {" "}
              Nom de l’alerte
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="alert_title"
              onChange={handleChange}
              value={emailAlert?.alert_title}
            />

            <Typography variant="h2" sx={{ mt: { xs: 2, sm: 4 }, mb: 1 }}>
              {" "}
              Mode d’envoi de l’alerte
            </Typography>

            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                sx={{ width: "100%" }}
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={"E-mail"}
              >
                <Stack direction={{ xs: "column", sm: "row" }} sx={{ my: 0 }}>
                  <FormControlLabel
                    value="E-mail"
                    control={<Radio />}
                    label="E-mail"
                    sx={{ minWidth: "150px" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={emailAlert?.email}
                    disabled
                  />
                </Stack>
              </RadioGroup>
            </FormControl>

            <Typography variant="h2" sx={{ mt: 4, mb: 2 }}>
              Choisir la fréquence
            </Typography>

            <ToggleButtonGroup
              color="primary"
              value={emailAlert?.alert_type}
              exclusive
              onChange={handleChange}
              aria-label="Hebdomadaire"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                flexDirection: { xs: "column", sm: "row" },
                textWrap: { xs: "wrap", sm: "nowrap" },
                borderRadius: "5px",
                textTransform: "inherit",
                gap: "15px",
                "& .Mui-selected": {
                  background: "#0066ee !important",
                  color: "#ffffff !important",
                  textTransform: "inherit",
                  width: "100%",
                },
                "& .MuiToggleButtonGroup-grouped": {
                  "&:not(:first-of-type)": {
                    border: "1px solid",
                    borderRadius: "5px",
                    textTransform: "inherit",
                    width: "100%",
                  },
                  "&:first-of-type": {
                    border: "1px solid",
                    borderRadius: "5px",
                    textTransform: "inherit",
                    width: "100%",
                  },
                },
              }}
            >
              <ToggleButton value="weekly">Hebdomadaire</ToggleButton>
              <ToggleButton value="daily">Quotidien</ToggleButton>
              <ToggleButton value="realTime">Temps réel</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ px: 2 }}
              onClick={() => {
                updateAlert();
              }}
            >
              Enregistrer
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
