
import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
export default function TrierPars({
  selectOption,
  setSelectOption,
  wishlistData,
  getWishlist,
  setSelectedStatus
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setSelectOption(event.target.value);
    const updateValue = {
      ...wishlistData,
      sortOption: event.target.value,
    };
    setSelectedStatus("Toutes")
    getWishlist(updateValue)
  };

  return (
    <Stack
      direction="row"
      mt={1}
      mb={0.5}
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        endIcon={<MdKeyboardArrowDown />}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: "inherit", color: "#606060" }}
      >
        Trier par
      </Button>
      <Menu
        id="basic-menu"
        sx={{ mt: 1 }}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <FormControl>
            <RadioGroup
              aria-labelledby="TrierPar"
            //   defaultValue="Date"
              name="TrierPar"
              value={selectOption}
              onChange={handleChange}
            >
              <FormControlLabel
                value="annonce récente"
                control={<Radio />}
                label="Annonce récente"
              />
              <FormControlLabel
                value="annonce ancienne"
                control={<Radio />}
                label="Annonce ancienne"
              />
              <FormControlLabel
                value="prix croissant"
                control={<Radio />}
                label="Prix croissant"
              />
              <FormControlLabel
                value="prix décroissant"
                control={<Radio />}
                label="Prix décroissant"
              />
              <FormControlLabel
                value="surface croissante"
                control={<Radio />}
                label="Surface croissante"
              />
              <FormControlLabel
                value="surface décroissante"
                control={<Radio />}
                label="Surface décroissante"
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
      </Menu>
    </Stack>
  );
}
