import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import {
  simplicitySpeed,
  totalFree,
  optimalPrecision,
  extendedVisibility,
} from "../../../../component/ConstantImagesURL";

const StyledCard = styled(Box)({
  width: "100%",
  height: "100%",
  textAlign: "start",
  borderRadius: 16,
  backgroundColor: "#EDF1F5",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  boxSizing: "border-box",
});

const StyledBox = styled(Box)({
  width: "100%",
});

const cardItems = [
  {
    img: simplicitySpeed,
    title: "Simplicité et Rapidité",
    description: "Votre annonce en ligne en moins d'une minute.",
  },
  {
    img: totalFree,
    title: "Gratuité Totale",
    description: "Aucun frais pour la publication de votre annonce.",
  },
  {
    img: optimalPrecision,
    title: "Précision Optimale",
    description:
      "Des annonces détaillées pour réussir votre projet immobilier.",
  },
  {
    img: extendedVisibility,
    title: "Visibilité Étendue",
    description:
      "Toucher efficacement notre communauté d'acheteurs et de locataires.",
  },
];

export default function PublishCard() {
  return (
    <StyledCard>
      <CardContent>
        <Typography
          sx={{ fontSize: 24, fontWeight: 600, color: "#263238" }}
          color="text.secondary"
          gutterBottom
        >
          Publier <span style={{ color: "#0066ee" }}>Facilement</span> Votre
          <br />
          Annonce Sur KI
        </Typography>
        <StyledBox>
          {cardItems.map((item, index) => (
            <Box
              key={index}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              my={2}
            >
              <img src={item.img} alt="People Icon" />
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 18, fontWeight: 600, color: "#263238" }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={12}
                  color="text.secondary"
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </StyledBox>
      </CardContent>
    </StyledCard>
  );
}
