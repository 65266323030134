import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useMemo, useState } from "react";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUser } from "../../Redux/reducer/reducer";
import { deleteRequest, getApiRequest, postApiRequest } from "../../apiHandler";
import Layout from "../../layout/Layout";
import { formatDate, formatPrice } from "../../utils/utils";
import AlertDialog from "../BiensEstimes/deletePopup";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  logo as KIlogo,
  agenzLogo,
  avitoLogo,
  marocannonces,
  mubawabLogo,
  saroutyLogo,
  yakeeyLogo,
} from "../../component/ConstantImagesURL";

const itemsPerPage = 6;

const websiteLogos = {
  "avito.ma": avitoLogo,
  "yakeey.com": yakeeyLogo,
  "mubawab.ma": mubawabLogo,
  "sarouty.ma": saroutyLogo,
  "agenz.ma": agenzLogo,
  "ki.ma": KIlogo,
  "marocannonces.com": marocannonces,
};

const MesAnnoncesListing = () => {
  const [annonceListing, setAnnonceListing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [markAsopen, setMarkAsopen] = useState(false);
  const [soldPrice, setSoldPrice] = useState(null);
  const [soldAdsId, setSoldAdsId] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const fetchAnnonceData = async () => {
    try {
      const { response, status } = await getApiRequest(`/api/ads/${user?._id}`);
      if (status === 200) setAnnonceListing(response);
      else setAnnonceListing([]);
    } catch (error) {
      console.error("Error fetching annonce data:", error);
    }
  };

  useEffect(() => {
    fetchAnnonceData();
  }, []);

  const handlePageChange = (event, page) => setCurrentPage(page);

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const deleteDataApi = async () => {
    if (deleteId) {
      try {
        const deleteRes = await deleteRequest(`/api/ads/${deleteId}`);
        if (deleteRes?.status === 200) {
          toast.success(deleteRes?.message);
          setOpen(false);
          await fetchAnnonceData();
        } else {
          toast.error(deleteRes?.message);
          await fetchAnnonceData();
        }
      } catch (error) {
        console.error("Error deleting data:", error);
        toast.error("Error deleting data");
      }
    }
  };

  const handleEdit = (item) => {
    navigate("/user/mes-publish-annonces", {
      state: { annonceEditData: item },
    });
  };

  const currentItems = useMemo(
    () =>
      annonceListing.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [annonceListing, currentPage]
  );

  const redirectOnAdsDetail = (adsId) => {
    const adsDetailsLink = `${process.env.REACT_APP_WEB_URL}/annonce-detail/${adsId}`;
    window.open(adsDetailsLink);
  };

  const handleDeleteClose = () => {
    setMarkAsopen(false);
  };
  const handleMarkAsSold = (adsId) => {
    setMarkAsopen(true);
    setSoldAdsId(adsId);
  };

  const markAsSold = async () => {
    if (soldAdsId && soldPrice != "" && soldPrice != 0 && soldPrice != null) {
      try {
        const { message, status } = await postApiRequest(
          `/api/ads/mark-as-sold/${soldAdsId}`,
          {
            price: soldPrice,
          }
        );
        if (status === 200) {
          toast.success(message);
          await fetchAnnonceData();
        } else {
          toast.error(message);
          await fetchAnnonceData();
        }
      } catch (error) {
        console.error("Error fetching annonce data:", error);
      }
      handleDeleteClose();
    }
  };
  const rePublishAds = async (adsId) => {
    if (adsId) {
      try {
        const { message, status } = await postApiRequest(
          `/api/ads/re-publish-ads/${adsId}`
        );
        if (status === 200) {
          toast.success(message);
          await fetchAnnonceData();
        } else {
          toast.error(message);
          await fetchAnnonceData();
        }
      } catch (error) {
        console.error("Error fetching annonce data:", error);
      }
    }
  };

  return (
    <Layout>
      <Box>
        <Paper
          elevation={3}
          sx={{ p: { xs: 2, sm: 4 }, width: "100%", borderRadius: "13px" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Mes annonces ({annonceListing.length})
            </Typography>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/user/mes-publish-annonces`}
              sx={{ px: 2, width: { xs: "100%", sm: "auto" } }}
            >
              Publier une annonce
            </Button>
          </Stack>
          <Box style={{ position: "relative" }}>
            {annonceListing.length > 0 ? (
              <Grid container rowSpacing={3} columnSpacing={3}>
                {currentItems.map((item) => {
                  const {
                    images_url,
                    property_type,
                    rooms,
                    area,
                    address,
                    city,
                    price,
                    agency_details,
                    website,
                    s3_images_url,
                    property_status = null,
                  } = item.publish_ads;
                  const logo = websiteLogos[website] || KIlogo;
                  const imagesUrl = s3_images_url
                    ? s3_images_url.map(
                        (image) => `https://content.1ki.ma/${image}`
                      )
                    : images_url;
                  const isDisabled = item.available || property_status !== null;
                  const cursor = isDisabled ? "not-allowed" : "pointer";
                  return (
                    <Grid item key={item?._id} xs={12} sm={6} md={4}>
                      <Box
                        variant="outlined"
                        sx={{
                          borderRadius: "10px",
                          boreder: "none",
                          height: "400px",
                          opacity: isDisabled ? 0.6 : 1,
                          cursor: cursor,
                          "&:hover": {
                            cursor: cursor,
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          mb="8px"
                          alignItems="center"
                        >
                          <Avatar
                            alt="annonces Web logo"
                            src={logo}
                            loading="lazy"
                            sx={{
                              width: "100px",
                              height: "40px",
                              maxHeight: "45px",
                              borderRadius: "0px",
                              fontSize: "15px",
                              cursor: cursor,
                            }}
                            onClick={() =>
                              property_status == null &&
                              redirectOnAdsDetail(item?.publish_ads_id)
                            }
                          />
                          {/* <Typography
                            variant="body1"
                            sx={{ ml: 1, fontWeight: "600" }}
                          >
                            {website}
                          </Typography> */}
                        </Stack>
                        <Box
                          component="img"
                          onClick={() =>
                            property_status == null &&
                            redirectOnAdsDetail(item?.publish_ads_id)
                          }
                          src={imagesUrl[0]}
                          loading="lazy"
                          alt="No image"
                          sx={{
                            borderRadius: "10px",
                            my: 1,
                            width: "100%",
                            height: "180px",
                            objectFit: "cover",
                            cursor: cursor,
                          }}
                        />
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Box sx={{ mt: 2, width: "100%" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              gap={"5px"}
                            >
                              <Typography
                                variant="h6"
                                className="fontMontserrat"
                                sx={{ cursor: cursor }}
                                onClick={() =>
                                  property_status == null &&
                                  redirectOnAdsDetail(item?.publish_ads_id)
                                }
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {property_type}
                                </span>
                                {rooms != null
                                  ? ` - ${rooms} Pièce${rooms > 1 ? "s" : ""}`
                                  : ""}
                                {area != null ? ` - ${area}m²` : ""}
                              </Typography>
                              {/* <FavoriteIcon
                                size={24}
                                cursor="pointer"
                                style={{ color: "red" }}
                              /> */}
                            </Stack>
                            <Typography
                              variant="subtitle1"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {address} {city}
                            </Typography>
                            <Typography variant="h5" color="primary.main">
                              {price == null
                                ? "0 DH"
                                : formatPrice(Number(price))}
                            </Typography>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ mt: 1, mb: 0.7 }}
                              >
                                Particulier
                              </Typography>
                              {!item?.available && property_status !== null && (
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    mt: 1,
                                    mb: 0.7,
                                    color: "red",
                                    fontWeight: 600,
                                  }}
                                >
                                  Radié de la liste
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ lineHeight: "15px" }}
                            >
                              {agency_details?.name}
                            </Typography>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "300" }}
                              >
                                Le {formatDate(item?.created_at).dateDMY}
                              </Typography>
                              {!item?.available && property_status !== null && (
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color: "red",
                                    fontWeight: 400,
                                  }}
                                >
                                  Le {formatDate(item.sold_at).dateDMY}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          bgcolor: "#f5f5f5",
                          my: 1,
                          borderRadius: "8px",
                          padding: "8px",
                          opacity: isDisabled ? 0.6 : 1,
                          cursor: cursor,
                        }}
                      >
                        <VisibilityIcon
                          sx={{
                            color: "#2f54eb",
                            marginRight: "8px",
                            fontSize: ".90rem",
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: ".85rem", fontWeight: "500" }}
                          color="text.primary"
                        >
                          {item.visitors_count} vues d'annonces
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          bgcolor: "#f5f5f5",
                          my: 1,
                          borderRadius: "8px",
                          padding: "8px",
                          opacity: isDisabled ? 0.6 : 1,
                          cursor: cursor,
                        }}
                      >
                        <VisibilityIcon
                          sx={{
                            color: "#2f54eb",
                            marginRight: "8px",
                            fontSize: ".90rem",
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: ".85rem", fontWeight: "500" }}
                          color="text.primary"
                        >
                          {item.search_count} nombre total de vues de recherche
                        </Typography>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Button
                          variant="outlined"
                          startIcon={
                            <FaPencil style={{ fontSize: "0.9rem" }} />
                          }
                          onClick={() => !isDisabled && handleEdit(item)}
                          disableRipple
                          disableElevation
                          sx={{
                            px: {
                              xs: 3,
                              sm: 5,
                              cursor: !isDisabled
                                ? "pointer !important"
                                : "not-allowed !important",
                              opacity: !isDisabled ? 1 : 0.9,
                              border: !isDisabled
                                ? "1px solid primary"
                                : "1px solid rgba(255, 0, 0, 0.7)",
                              filter: !isDisabled ? "none" : "grayscale(80%)",
                              transition: "all 0.3s ease-in-out",
                              "&:hover": isDisabled && {
                                background: "inherit",
                                boxShadow: "none",
                                color: "#4d4a4a",
                              },
                            },
                          }}
                        >
                          Modifier
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={
                            <FaTrashCan style={{ fontSize: "0.9rem" }} />
                          }
                          disableRipple
                          disableElevation
                          sx={{
                            px: { xs: 3, sm: 5 },
                            cursor: !isDisabled
                              ? "pointer !important"
                              : "not-allowed !important",
                            opacity: !isDisabled ? 1 : 0.9,
                            border: !isDisabled
                              ? "1px solid primary"
                              : "1px solid rgba(255, 0, 0, 0.7)",
                            filter: !isDisabled ? "none" : "grayscale(80%)",
                            transition: "all 0.3s ease-in-out",
                            "&:hover": isDisabled && {
                              background: "inherit",
                              boxShadow: "none",
                              color: "#4d4a4a",
                            },
                          }}
                          onClick={() =>
                            !isDisabled &&
                            handleDeleteOpen(item?.publish_ads_id)
                          }
                        >
                          Supprimer
                        </Button>
                      </Stack>
                      <Button
                        variant="outlined"
                        color={property_status == null ? "primary" : "error"}
                        fullWidth
                        disableRipple
                        disableElevation
                        sx={{
                          px: { xs: 3, sm: 5 },
                          mt: 0.5,
                          cursor: !isDisabled
                            ? "pointer !important"
                            : "not-allowed !important",
                          opacity: !isDisabled ? 1 : 0.9,
                          border: !isDisabled
                            ? "1px solid primary"
                            : "1px solid rgba(255, 0, 0, 0.7)",
                          filter: !isDisabled ? "none" : "grayscale(80%)",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": isDisabled && {
                            background: "inherit",
                            boxShadow: "none",
                            color: "#4d4a4a",
                          },
                        }}
                        onClick={() =>
                          !isDisabled && handleMarkAsSold(item._id)
                        }
                        startIcon={<FaPencil style={{ fontSize: "0.9rem" }} />}
                      >
                        {property_status == null
                          ? "Marquer comme vendu"
                          : "Sold"}
                      </Button>
                      {item.available && (
                        <Button
                          variant="outlined"
                          color={"success"}
                          fullWidth
                          sx={{
                            px: { xs: 3, sm: 5 },
                            mt: 0.5,
                            textTransform: "capitalize",
                          }}
                          onClick={() => rePublishAds(item.publish_ads_id)}
                          startIcon={
                            <FaPencil style={{ fontSize: "0.9rem" }} />
                          }
                        >
                          Republish
                        </Button>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box display={"flex"} justifyContent={"center"} mt={5}>
                Données introuvables !
              </Box>
            )}
            {annonceListing.length > itemsPerPage && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={3}
              >
                <Pagination
                  count={Math.ceil(annonceListing.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={deleteDataApi}
      />
      <Dialog
        open={markAsopen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ borderBottom: "1px solid #dee2e6", mb: "16px" }}
        >
          Marquer comme vendu
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDeleteClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "18px", color: "#6c757d" }} />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              component="form"
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "22.5px",
                  backgroundColor: "#FFFFFF",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
              container
              rowSpacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                  Vendu Prix
                  <span style={{ color: "#EC544B" }}>*</span>
                </Typography>
                <TextField
                  placeholder="Exemple : 1 000 000"
                  id="outlined-start-adornment"
                  name="price"
                  value={soldPrice}
                  onChange={(e) => setSoldPrice(e.target.value)}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">DH</InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "16px",
                      "& fieldset": {
                        borderColor:
                          soldPrice != "" && soldPrice != 0
                            ? "rgba(132, 159, 186, 0.45)"
                            : "rgb(220, 53, 69)",
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "& textarea::-webkit-scrollbar": {
                        width: "0.6vh !important",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pt: 2 }}>
          <Button
            onClick={handleDeleteClose}
            style={{
              textTransform: "capitalize",
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#2f54eb",
              color: "white",
            }}
          >
            Annuler
          </Button>
          <Button
            autoFocus
            className="delete-modal-btn-yes"
            onClick={markAsSold}
            style={{
              backgroundColor: "#dc3545",
              color: "white",
              fontSize: "13px",
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            Marquer comme vendu
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default MesAnnoncesListing;
