// const pieParams = {
//   width: 265,
//   height: 265,
//   margin: { right: 12 },
// };
import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts/PieChart";
import { FaSquare } from "react-icons/fa6";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const palette = ["#7BACF5", "#0066EE"];

export default function CircularPie({ totalPrice, loanAmount, totalInterest }) {
  const theme = useTheme();

  // Define different pie chart sizes for different breakpoints
  const xs = useMediaQuery(theme.breakpoints.only("xs")); // Extra-small devices (phones)
  const sm = useMediaQuery(theme.breakpoints.only("sm")); // Small devices (tablets)
  const md = useMediaQuery(theme.breakpoints.only("md")); // Medium devices (desktops)
  const lg = useMediaQuery(theme.breakpoints.only("lg")); // Large devices (large desktops)
  const xl = useMediaQuery(theme.breakpoints.only("xl")); // Extra-large devices (large screens)

  // Set the pie chart size based on the current breakpoint
  const pieSize = xs ? 195 : sm ? 270 : md ? 250 : lg ? 260 : 270;

  return (
    <Stack direction="column" width="100%" alignItems="center" spacing={4}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <PieChart
          colors={palette}
          series={[
            {
              data: [{ value: totalInterest }, { value: loanAmount }],
              innerRadius: pieSize / 2.7,
            },
          ]}
          width={pieSize}
          height={275}
          margin={{ right: 12 }}
        />
        <Typography
          variant="body1"
          style={{
            position: "absolute",
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          {totalPrice} DH
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginTop: "12px !important" }}
      >
        <Typography variant="body1" style={{ fontSize: 14, fontWeight: 500 }}>
          Par mois
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: "8px !important" }}
      >
        <Box
          display="flex"
          alignItems="center"
          bgcolor="#EFEFEF"
          p={1}
          sx={{ borderRadius: "4px" }}
        >
          <FaSquare color="#0066EE" />
          <Typography
            variant="body1"
            sx={{ marginLeft: "8px", fontSize: "12px" }}
          >
            Montant du prêt
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          bgcolor="#EFEFEF"
          ml={1}
          p={1}
          sx={{ borderRadius: "4px" }}
        >
          <FaSquare color="#7BACF5" />
          <Typography
            variant="body1"
            sx={{ marginLeft: "8px", fontSize: "12px" }}
          >
            Total des intérêts
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}
