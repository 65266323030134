import React from "react";
import Layout from "../../layout/Layout";
import { Box } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./ChangePassword";
import ProfileUpdate from "./ProfileUpdate";
import Preferences from "./Preferences";

export default function MonCompteIndex() {
  return (
    <Layout>
      <Box sx={{ maxWidth: { xs: "100%", lg: "66.666667%" } }}>
        <ProfileUpdate />
        <ChangePassword />
        <Preferences />
      </Box>
    </Layout>
  );
}
