import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

import { ReactComponent as HendIcon } from "./assets/Bienvenue.svg";

// import { ReactComponent as Alertes } from "../../assets/images/TopTitle/Alertes.svg";
// import { ReactComponent as Bienv } from "../../assets/images/TopTitle/Bienv.svg";
// import { ReactComponent as Projet } from "../../assets/images/TopTitle/Projet.svg";
// import { ReactComponent as ProjetDe } from "../../assets/images/TopTitle/ProjetDe.svg";
// import { ReactComponent as Sauvega } from "../../assets/images/TopTitle/Sauvega.svg";

import DecouvrezNos from "./DecouvrezNos";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/reducer/reducer";
import { getApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";

import Alertes from "../../assets/images/buyerSellerMenuIcon/alertes.svg";
import MesAnnounces from "../../assets/images/buyerSellerMenuIcon/mesAnnounces.svg";
import Bienv from "../../assets/images/buyerSellerMenuIcon/biensEstimes.svg";
import Projet from "../../assets/images/buyerSellerMenuIcon/projectDeVente.svg";
import ProjetDe from "../../assets/images/buyerSellerMenuIcon/projectDChat.svg";
import Sauvega from "../../assets/images/buyerSellerMenuIcon/announcesAimees.svg";

export default function TableauDeBord() {
  const userData = useSelector(selectUser);
  const [totalRecord, setTotalRecord] = useState({
    total_estimations: 0,
    total_wishlists: 0,
    total_alerts: 0,
    total_sales_project: 0,
    total_purchase_project: 0,
    total_published_ads: 0,
  });

  const TopItem = [
    {
      id: 1,
      Title: "Biens estimés",
      Num: "",
      Icon: Bienv,
      Link: "/user/mes-biens",
      key: "total_estimations",
    },
    {
      id: 2,
      Title: "Projet de vente",
      Num: "",
      Icon: Projet,
      Link: "/user/mon-projet-de-vente",
      key: "total_sales_project",
    },
    {
      id: 3,
      Title: "Projet d’achat",
      Num: "",
      Icon: ProjetDe,
      Link: "/user/mon-projet-dachat",
      key: "total_purchase_project",
    },
    {
      id: 4,
      Title: "Sauvegardé",
      Num: "",
      Icon: Sauvega,
      Link: "/user/mes-annonces-sauvegardées",
      key: "total_wishlists",
    },
    {
      id: 5,
      Title: "Alertes",
      Num: "",
      Icon: Alertes,
      Link: "/user/mes-alertes",
      key: "total_alerts",
    },
    {
      id: 6,
      Title: "Mes annonces",
      Num: "",
      Icon: MesAnnounces,
      Link: "/user/annonces",
      key: "total_published_ads",
    },
  ];
  // Call api
  const getTotalRecordCount = async () => {
    const totalRecordRes = await getApiRequest("/api/get-total-records-count");
    if (totalRecordRes?.status === 200) {
      setTotalRecord(totalRecordRes.response);
    } else {
      toast.error(totalRecordRes?.message);
    }
  };
  useEffect(() => {
    getTotalRecordCount();
  }, []);

  // Re-render component for changes
  useEffect(() => {}, [totalRecord]);

  return (
    <Layout>
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            variant="h2"
            sx={{ fontWeight: "500", textTransform: "capitalize" }}
          >
            Bienvenue {userData?.firstname} {userData?.lastname}
          </Typography>
          <HendIcon />
        </Stack>

        <Box sx={{ my: 3 }}>
          <Grid container spacing={2} columns={12}>
            {TopItem?.map((Item) => (
              <Grid item xs={5} md={4} key={Item.id}>
                <Paper
                  component={Link}
                  to={Item.Link}
                  elevation={3}
                  sx={{
                    py: { xs: 1.5, sm: 2 },
                    px: 2,
                    minHeight: "70px",
                    width: "100%",
                    borderRadius: "13px",
                    display: "block",
                  }}
                >
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={1.5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      {/* <Item.Icon /> */}
                      <img
                        style={{
                          width: "32px",
                          height: "32px",
                          marginRight: "10px",
                        }}
                        src={Item.Icon}
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography
                        noWrap
                        variant="h2"
                        sx={{ fontWeight: "600" }}
                      >
                        {totalRecord[Item.key]}
                      </Typography>
                      <Typography noWrap variant="subtitle2" component={Box}>
                        {Item.Title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <DecouvrezNos />
        </Box>
      </Box>
    </Layout>
  );
}
