import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function AlertDialog({ open, setOpen, deleteDataApi, check }) {
  const handleDeleteClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {open ? (
        <Dialog
          open={open}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ borderBottom: "1px solid #dee2e6", mb: "16px" }}
          >
            Supprimer
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleDeleteClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon style={{ fontSize: "18px", color: "#6c757d" }} />
          </IconButton>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                position: "absolute",
                top: "73px",
                paddingRight: "20px",
              }}
            >
              {check
                ? "Etes-vous sûr de vouloir supprimer ceci des éléments de la liste de souhaits ?"
                : "Êtes-vous sûr de vouloir supprimer ceci ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ pt: 2 }}>
            <Button
              onClick={handleDeleteClose}
              style={{
                textTransform: "capitalize",
                fontSize: "13px",
                fontWeight: "600",
                backgroundColor: "#2f54eb",
                color: "white",
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={() => {
                deleteDataApi();
              }}
              autoFocus
              className="delete-modal-btn-yes"
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                fontSize: "13px",
                textTransform: "capitalize",
                fontWeight: "600",
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
