import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

export default function InformationsSupplémentaires({ formData, setFormData }) {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const excludedTypes = ["maison", "villa", "riad"];

  const checkboxOptions = [
    ...(formData["property_type"] === "studio"
      ? []
      : [
          { name: "duplex", label: "Duplex" },
          { name: "service_room", label: "Chambre de service" },
          { name: "not_overlooked", label: "Sans vis-à-vis" },
          { name: "furnished", label: "Meublé" },
        ]),

    ...(formData["property_type"] === "studio"
      ? [
          { name: "furnished", label: "Meublé" },
          { name: "service_room", label: "Chambre de service" },
          { name: "terrace", label: "Terrasse" },
          { name: "elevator", label: "Ascenseur" },
          { name: "balcony", label: "Balcon" },
          { name: "parking", label: "Parking" },
          { name: "secure_residence", label: "Résidence sécurisée" },
        ]
      : [
          ...(excludedTypes.includes(formData["property_type"])
            ? [
                { name: "pool", label: "Piscine" },
                { name: "solar_water_heater", label: "Chauffe eau solaire" },
                { name: "garage", label: "Garage" },
                { name: "garden", label: "Jardin" },
                { name: "closed_residence", label: "Résidence fermée" },
                {
                  name: "surveillance_camera",
                  label: "Camera de surveillance",
                },
                {
                  name: "playground",
                  label: "Espace de jeux enfants",
                },
                { name: "terrace", label: "Terrasse" },
              ]
            : [
                { name: "balcony", label: "Balcon" },
                { name: "terrace", label: "Terrasse" },
                { name: "parking", label: "Parking" },
                { name: "basement", label: "Box en sous-sol" },
                { name: "elevator", label: "Ascenseur" },
                { name: "secure_residence", label: "Résidence sécurisée" },
              ]),
        ]),
    ...(formData["property_type"] === "immeuble"
      ? [{ name: "garage", label: "Garage" }]
      : []),
  ].filter(Boolean);

  return (
    <Box mt={6}>
      <Typography
        variant="body1"
        fontSize={24}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Informations supplémentaires
      </Typography>
      <Grid
        container
        rowSpacing={1}
        spacing={4}
        className="step_four_check"
        mt={1}
      >
        {checkboxOptions.map((option, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[option.name] || false}
                  name={option.name}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#849FBA",
                    padding: "3px 10px",
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px",
                      borderRadius: "4px",
                    },
                    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                      color: "#0066EE",
                    },
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#263238",
                    textWrap: "nowrap",
                  }}
                >
                  {option.label}
                </Typography>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
