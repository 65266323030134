import { driver as Driver } from "driver.js";

const manageOverlay = (action) => {
  const overlayId = "tour-overlay";
  const overlay = document.getElementById(overlayId);

  if (action === "add" && !overlay) {
    const newOverlay = document.createElement("div");
    newOverlay.id = overlayId;
    Object.assign(newOverlay.style, {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: "9999",
      pointerEvents: "auto",
    });
    newOverlay.addEventListener("click", (e) => e.preventDefault());
    document.body.appendChild(newOverlay);
  } else if (action === "remove" && overlay) {
    overlay.remove();
  }
};

const addSkipButton = (popover, driverObj, globalTourKey) => {
  const skipButton = document.createElement("button");
  skipButton.textContent = "Benne";
  skipButton.addEventListener("click", () => {
    driverObj.destroy();
    localStorage.setItem(globalTourKey, "true");
    manageOverlay("remove");
  });

  const { footerButtons, previousButton } = popover;
  if (footerButtons && previousButton) {
    footerButtons.insertBefore(skipButton, previousButton);
  }
};

export const startDriverTour = (steps, routeKey, globalTourKey) => {
  const currentPath = window.location.pathname;
  if (currentPath !== "/user/mes-publish-annonces") return;

  const isTourCompleted =
    localStorage.getItem(routeKey) || localStorage.getItem(globalTourKey);
  if (isTourCompleted) return;
  const handlePopState = () => {
    if (window.location.pathname !== "/user/mes-publish-annonces") {
      driverObj?.destroy();
      manageOverlay("remove");
      window.removeEventListener("popstate", handlePopState);
    }
  };
  const driverObj = new Driver({
    showProgress: true,
    allowClose: false,
    nextBtnText: "Suivant",
    prevBtnText: "Précédent",
    doneBtnText: "Faite",
    onHighlightStarted: () => manageOverlay("add"),
    onDeselected: () => manageOverlay("add"),
    onDestroyed: () => {
      manageOverlay("remove");
      window.removeEventListener("popstate", handlePopState);
    },
    steps: steps.map((step, index) => ({
      ...step,
      popover: {
        ...step.popover,
        onNextClick: () => {
          const isLastStep = index === steps.length - 1;
          if (step.onNextClick) {
            step.onNextClick(driverObj);
          } else if (isLastStep) {
            driverObj.destroy();
            localStorage.setItem(routeKey, "true");
            manageOverlay("remove");
            window.removeEventListener("popstate", handlePopState);
          } else {
            driverObj.moveNext();
          }
        },
        onPrevClick: () => {
          step.onPrevClick
            ? step.onPrevClick(driverObj)
            : driverObj.movePrevious();
        },
        onPopoverRender: (popover) => {
          step.onPopoverRender
            ? step.onPopoverRender(popover, driverObj)
            : addSkipButton(popover, driverObj, globalTourKey);
        },
      },
    })),
  });
  window.addEventListener("popstate", handlePopState);
  driverObj.drive();
};
