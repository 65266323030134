import React, { useEffect } from "react";

const MapControls = ({ changeStyle, mapStyle }) => {
  useEffect(() => {
    // Select the target div
    const targetDiv = document.querySelector(
      ".mapboxgl-ctrl.mapboxgl-ctrl-group"
    );

    if (targetDiv) {
      // Create the button element
      const button = document.createElement("button");
      //   button.className = "mapStyleChangeBtn";
      //   button.style.width = isMobile ? "fit-content" : "";
      //   button.style.borderRadius = isMobile ? "4px" : "";

      // Set the click event
      button.onclick = () => changeStyle();

      // Add SVG content based on mapStyle
      button.innerHTML = mapStyle
        ? `
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-globe-central-south-asia" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M4.882 1.731a.48.48 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.7.7 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a1 1 0 0 0-.462-.04 7 7 0 0 1 2.45-2.027m-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.8.8 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.45.45 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282l.04-.001a7.003 7.003 0 0 1-12.658.905Z"/>
        </svg>
      `
        : `
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-map" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"/>
        </svg>
      `;

      // Append the button to the first child of the target div
      targetDiv.insertBefore(button, targetDiv.firstChild);
    }

    // Cleanup function to remove the button when the component unmounts
    return () => {
      if (targetDiv && targetDiv.firstChild) {
        targetDiv.removeChild(targetDiv.firstChild);
      }
    };
  }, [changeStyle, mapStyle]);

  return null; // Since we're manipulating the DOM directly, we don't need to render anything
};

export default MapControls;
