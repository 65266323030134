import React, { useEffect, useState } from "react";
import { selectUser, loginUser } from "../Redux/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../apiHandler";
import { LoaderGif } from "../component/ConstantImagesURL";
import { Box } from "@mui/material";

function Protected({ children }) {
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  if (!token) {
    token = localStorage.getItem("token");
  }

  useEffect(() => {
    let isMounted = true;
    const redirectToSignIn = async () => {
      try {
        if (!token) {
          await getApiRequest(
            `/api/check-users-logged-activity/${token}/${user?._id}`
          );
          setLoading(true);
          localStorage.removeItem("token");
          dispatch(loginUser({}));
          window.location.replace(`${process.env.REACT_APP_WEB_URL}/sign-in`);
        } else {
          setLoading(true);
          localStorage.removeItem("token");
          dispatch(loginUser({}));
          window.location.replace(`${process.env.REACT_APP_WEB_URL}/sign-in`);
        }
      } catch (error) {
        console.error("Error checking user activity:", error);
      }
    };
    const checkTokenAndRedirect = async () => {
      if (!token) {
        redirectToSignIn();
        return;
      }
      try {
        const tokenResponse = await getApiRequest(
          `/api/check-users-logged-activity/${token}`
        );
        if (isMounted) {
          if (tokenResponse?.status === 200) {
            dispatch(loginUser(tokenResponse?.response));
            setLoading(false);
            localStorage.setItem("token", tokenResponse?.response?.token);
          } else {
            redirectToSignIn();
          }
        }
      } catch (error) {
        console.log("catch error", error);
        redirectToSignIn();
      }
    };
    checkTokenAndRedirect();
    return () => {
      isMounted = false;
    };
  }, [dispatch, token]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <img src={LoaderGif} alt="Loader Gif" style={{ width: "5%" }} />
      </Box>
    );
  }

  return token ? (
    children
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img src={LoaderGif} alt="Loader Gif" style={{ width: "5%" }} />
    </Box>
  );
}

export default Protected;
