import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Beansio from "../assets/images/SidebarIcon/Beansio.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginUser, selectUser } from "../Redux/reducer/reducer";
import { deleteRequest } from "../apiHandler";
import TableauDeBoard from "../assets/images/buyerSellerMenuIcon/tableauDeBoard.svg";
import Alertes from "../assets/images/buyerSellerMenuIcon/alertes.svg";
import MesAnnounces from "../assets/images/buyerSellerMenuIcon/mesAnnounces.svg";
import BiensEstimes from "../assets/images/buyerSellerMenuIcon/biensEstimes.svg";
import ProjectDeVente from "../assets/images/buyerSellerMenuIcon/projectDeVente.svg";
import ProjectDChat from "../assets/images/buyerSellerMenuIcon/projectDChat.svg";
import AnnouncesAimees from "../assets/images/buyerSellerMenuIcon/announcesAimees.svg";
import logOut from "../assets/images/buyerSellerMenuIcon/logOut.svg";

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Beansio})`,
  backgroundColor: "#f0f0f0",
  backgroundPosition: "bottom right",
  backgroundSize: "27%",
  backgroundRepeat: "no-repeat",
  paddingTop: "1rem",
  paddingBottom: "4rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  marginTop: "0.5rem",
}));

export default function Sidebar() {
  const user = useSelector(selectUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const [openAnnonces, setOpenAnnonces] = useState(
    location.pathname.includes("/user/annonces") ||
      location.pathname.includes("/user/mes-publish-annonces")
  );

  const handleToggleAnnonces = () => {
    setOpenAnnonces(!openAnnonces);
  };

  const MenuList = [
    {
      id: 1,
      Name: "Tableau de bord",
      link: "/user/dashboard",
      Icon: TableauDeBoard,
    },
    {
      id: 2,
      Name: "Alertes",
      link: "/user/mes-alertes",
      Icon: Alertes,
    },
    {
      id: 3,
      Name: "Annonces",
      Icon: MesAnnounces,
      announcesMenu: [
        {
          id: 1,
          Name: "Mes annonces",
          link: "/user/annonces",
          // Icon: MesAnnounces,
        },
        {
          id: 2,
          Name: "Publier une annonce",
          link: "/user/mes-publish-annonces",
          // Icon: MesAnnounces,
        },
      ],
    },
    {
      id: 4,
      Name: "Biens estimés",
      link: "/user/mes-biens",
      Icon: BiensEstimes,
    },
    {
      id: 5,
      Name: "Projet de vente",
      link: "/user/mon-projet-de-vente",
      Icon: ProjectDeVente,
    },
    {
      id: 6,
      Name: "Projet d’achat",
      link: "/user/mon-projet-dachat",
      Icon: ProjectDChat,
    },
    {
      id: 7,
      Name: "Sauvegardées",
      link: "/user/mes-annonces-sauvegardées",
      Icon: AnnouncesAimees,
    },
    {
      id: 8,
      Name: "Déconnexion",
      Icon: logOut,
    },
  ];

  const logout = async () => {
    dispatch(loginUser(null));
    localStorage.removeItem("token");
    const logoutRes = await deleteRequest(
      `/api/logout-users-activity/${user.token}`
    );
    if (logoutRes?.status === 200) {
      window.location.replace(
        `${process.env.REACT_APP_WEB_URL}/sign-in?origin=true`
      );
    } else {
      toast.error(logoutRes?.message);
    }
  };

  return (
    <>
      <List sx={{ padding: "15px 27px" }}>
        {MenuList.map((Item) => (
          <React.Fragment key={Item.Name}>
            <ListItem className="sidebarhover" disableGutters disablePadding>
              <ListItemButton
                component={Item.link ? NavLink : "div"}
                to={Item.link}
                sx={{
                  padding: "8px 12px",
                  "& .MuiListItemText-primary, & img": {
                    color: Item.id === 8 && "red !important",
                  },
                  ":hover": {
                    borderRadius: "8px",
                    "& .MuiListItemText-primary, & img": {
                      color:
                        Item.id === 8 ? "red !important" : "#0066EE !important",
                    },
                    "& img": {
                      filter:
                        "brightness(100%) saturate(378%) hue-rotate(20deg)",
                    },
                  },
                  "&.active": {
                    "& .MuiListItemText-primary, & img": {
                      color: "#2f54eb",
                    },
                    "& img": {
                      filter:
                        "brightness(100%) saturate(378%) hue-rotate(20deg)",
                    },
                  },
                }}
                onClick={() => {
                  if (Item.id === 3) {
                    handleToggleAnnonces();
                  }
                  if (Item.id === 8) {
                    logout();
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "inherit",
                    fontSize: "22px",
                    minWidth: "36px",
                  }}
                >
                  <img src={Item?.Icon} alt={Item?.Name} />
                </ListItemIcon>
                <ListItemText primary={Item.Name} />
                {Item.id === 3 &&
                  (openAnnonces ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </ListItem>
            {Item.announcesMenu && (
              <Collapse
                in={Item.id === 3 && openAnnonces}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {Item.announcesMenu.map((subItem) => (
                    <ListItem
                      key={subItem.id}
                      className="sidebarhover"
                      disableGutters
                      disablePadding
                    >
                      <ListItemButton
                        component={NavLink}
                        to={subItem.link}
                        sx={{
                          padding: "8px 12px",
                          paddingLeft: "7vh",
                          ":hover": {
                            borderRadius: "8px",
                            "& .MuiListItemText-primary, & img": {
                              color: "#0066EE !important",
                            },
                            "& img": {
                              filter:
                                "brightness(100%) saturate(378%) hue-rotate(20deg)",
                            },
                          },
                          "&.active": {
                            "& .MuiListItemText-primary, & img": {
                              color: "#2f54eb",
                            },
                            "& img": {
                              filter:
                                "brightness(100%) saturate(378%) hue-rotate(20deg)",
                            },
                          },
                        }}
                      >
                        {/* <ListItemIcon
                          sx={{
                            color: "inherit",
                            fontSize: "22px",
                            minWidth: "36px",
                          }}
                        >
                          <img src={subItem?.Icon} alt={subItem?.Name} />
                        </ListItemIcon> */}
                        <ListItemText primary={subItem.Name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <CustomBox>
        <Typography variant="subtitle1" color="primary" sx={{ mb: 1 }}>
          Besoin d’aide ?
        </Typography>
        <Stack
          direction="column"
          spacing={0.5}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography
            variant="subtitle2"
            component={NavLink}
            to={`${process.env.REACT_APP_WEB_URL}/cgu`}
            target="blank"
            sx={{ color: "#4d4a4a", fontWeight: "300", fontSize: "0.8rem" }}
          >
            Conditions générales d’utilisation" Politique de confidentialité
            Paramètres des cookies
          </Typography>
          <Typography
            variant="subtitle2"
            component={NavLink}
            to={"https://guide.ki.immo/"}
            target="blank"
            sx={{ color: "#4d4a4a", fontWeight: "300", fontSize: "0.8rem" }}
          >
            Guide de l’immobilier
          </Typography>
        </Stack>
      </CustomBox>
      <Typography
        align="center"
        variant="subtitle1"
        sx={{ mt: 3, display: { xs: "none", sm: "block" } }}
      >
        {" "}
        © KI. Copyright 2023
      </Typography>
    </>
  );
}
