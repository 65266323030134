import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { postApiRequest } from "../../apiHandler";
import { Link } from "react-router-dom";

export default function EtesVous({
  estimationId,
  setEstimationId,
  getProjetDeVenteData,
}) {
  const [formData, setFormData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = (formData) => {
    if (!formData.estimation_id) {
      return "Doit sélectionner une carte";
    }
    if (!formData.annonce_sell) {
      return "Annoncer la vente est obligatoire";
    }
    if (!formData.plan_to_sell) {
      return "Un plan de vente est requis";
    }
    return "";
  };

  const submitData = async (e) => {
    const finalProjectData = { ...formData, estimation_id: estimationId };
    const errorMessage = validateFormData(finalProjectData);
    if (errorMessage) {
      return toast.error(errorMessage);
    }
    try {
      await postApiRequest("/api/submit-projet-de-vente", finalProjectData);
      getProjetDeVenteData();
      toast.success("Données soumises avec succès !");
      setFormData({
        own_property: "Oui",
        invest_real_estate: "Oui",
      });
      setEstimationId("");
    } catch (error) {
      console.error("Error Submitting Data:", error);
    }
  };

  useEffect(() => {
    setFormData({
      own_property: "Oui",
      invest_real_estate: "Oui",
    });
  }, []);

  return (
    <>
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6}>
          <Box component="form" sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mt: 2.2, display: "block" }}>
              <FormLabel
                id="proprietaire"
                sx={{ fontWeight: "500", color: "#4D4A4A", fontSize: "0.9rem" }}
              >
                êtes-vous propriétaire de ce bien ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="proprietaire"
                name="own_property"
                value={formData["own_property"] || ""}
                onChange={handleChange}
              >
                <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="Non" control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2.2 }}>
              <FormLabel
                id="Quel"
                sx={{
                  fontWeight: "500",
                  color: "#4D4A4A",
                  fontSize: "0.9rem",
                  mb: 1,
                }}
              >
                Quel type de résidence ?
              </FormLabel>
              <Select
                id="Quel"
                name="annonce_sell"
                value={formData["annonce_sell"] || ""}
                onChange={handleChange}
                size="small"
                sx={{ maxWidth: { xs: "100%", sm: "65%" } }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Sélectionnez</em>
                </MenuItem>
                <MenuItem value={"Une résidence principale"}>
                  Une résidence principale
                </MenuItem>
                <MenuItem value={"Une résidence secondaire"}>
                  Une résidence secondaire
                </MenuItem>
                <MenuItem value={"Une résidence locative"}>
                  Une résidence locative
                </MenuItem>
                <MenuItem value={"Autre"}> Autre </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <FormLabel
                id="Envisagez-vous "
                sx={{
                  fontWeight: "500",
                  color: "#4D4A4A",
                  fontSize: "0.9rem",
                  mb: 1,
                }}
              >
                Envisagez-vous de vendre ce bien ?
              </FormLabel>
              <Select
                id="Envisagez-vous"
                name="plan_to_sell"
                value={formData["plan_to_sell"] || ""}
                onChange={handleChange}
                size="small"
                sx={{ maxWidth: { xs: "100%", sm: "65%" } }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>---Choisir---</em>
                </MenuItem>
                <MenuItem value={"Oui, j'ai déjà commencé la vente"}>
                  Oui, j'ai déjà commencé la vente
                </MenuItem>
                <MenuItem value={"Oui, dès que possible"}>
                  Oui, dès que possible
                </MenuItem>
                <MenuItem value={"Oui, d'ici 3 mois"}>
                  Oui, d'ici 3 mois
                </MenuItem>
                <MenuItem value={"Oui, d'ici 6 mois"}>
                  Oui, d'ici 6 mois
                </MenuItem>
                <MenuItem value={"Oui, dans plus de 6 mois"}>
                  Oui, dans plus de 6 mois
                </MenuItem>
                <MenuItem value={"Non, je n'ai pas de projet de vente"}>
                  Non, je n'ai pas de projet de vente
                </MenuItem>
                <MenuItem value={"Non, je viens de le vendre"}>
                  Non, je viens de le vendre
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ textAlign: "left", mt: 3 }}>
              <FormLabel
                id="Pour "
                sx={{ fontWeight: "500", color: "#4D4A4A", fontSize: "0.9rem" }}
              >
                Pour réduire vos impôts souhaitez-vous investir dans
                l’immobilier neuf ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="Pour"
                name="invest_real_estate"
                value={formData["invest_real_estate"] || ""}
                onChange={handleChange}
              >
                <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="Non" control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              sx={{ mt: 2, width: { xs: "100%", sm: "auto" } }}
              onClick={submitData}
            >
              Valider
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              maxWidth: "380px",
              mx: "auto",
            }}
          >
            <Link to={"https://guide.ki.immo"} target="blank">
              <Paper
                elevation={3}
                sx={{
                  py: { xs: 1.5, sm: 4 },
                  px: { xs: 1.5, sm: 4 },
                  width: "100%",
                  borderRadius: "13px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: ".9rem",
                      fontWeight: "600",
                      color: "#4d4a4a",
                    }}
                  >
                    {""}
                    Le guide pour
                    <span style={{ color: "#2f54eb", fontWeight: "900" }}>
                      vendre son bien immobilier
                    </span>
                  </Typography>
                  <Divider
                    sx={{
                      my: 2.5,
                      borderWidth: "2px",
                      borderColor: "#4d4a4a",
                      borderRadius: "100px",
                      maxWidth: "40%",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: ".85rem",
                      fontWeight: "400",
                      color: "#4d4a4a",
                    }}
                  >
                    Retrouvez toutes les informations et astuces pour vendre
                    votre bien immobilier en France dans les meilleures
                    conditions.
                  </Typography>
                </Box>
              </Paper>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
