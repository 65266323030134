import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
} from "@mui/material";
import debounce from "lodash.debounce";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IoSearch } from "react-icons/io5";
import { toast } from "react-toastify";

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
}));

const SearchListBox = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[8],
  zIndex: 1,
  borderRadius: "4px",
  overflowY: "auto",
  maxWidth: "700px",
  display: "flex",
}));

const StepOneAutoComplete = ({
  address,
  setAddress,
  handleSelect,
  isMobileIcons,
  markerRefFunction,
}) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listVisible, setListVisible] = useState(false);
  const inputRef = useRef(null);
  const suggestionBoxRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, []);

  const fetchSuggestions = async (query) => {
    if (!query || !autocompleteService.current) return;
    setLoading(true);
    autocompleteService.current.getPlacePredictions(
      {
        input: query,
        types: ["geocode"],
        componentRestrictions: { country: "ma" },
      },
      (predictions, status) => {
        setLoading(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions || []);
        } else {
          setSuggestions([]);
        }
      }
    );
  };

  const fetchPlaceDetails = async (placeId) => {
    if (!placeId || !placesService.current) return;
    return new Promise((resolve, reject) => {
      placesService.current.getDetails(
        {
          placeId: placeId,
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const location = place.geometry?.location;
            if (location) {
              const lat = location.lat();
              const lng = location.lng();
              resolve({
                lat,
                lng,
                address: place.formatted_address,
                fullAddress: place.formatted_address,
                place,
              });
            } else {
              reject("No location found");
            }
          } else {
            reject("Failed to get place details");
          }
        }
      );
    });
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  useEffect(() => {
    if (address !== query) {
      setQuery(address);
    }
  }, [address, query]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setAddress(value);
    if (value) {
      debouncedFetchSuggestions(value);
      setListVisible(true);
    } else {
      setSuggestions([]);
      setListVisible(true);
    }
  };

  const handleSelectSuggestion = async (suggestion) => {
    try {
      const placeDetails = await fetchPlaceDetails(suggestion.place_id);
      handleSelect(placeDetails);
      setQuery(placeDetails.fullAddress);
      setSuggestions([]);
      setListVisible(false);
    } catch (error) {
      toast.error("Error fetching place details:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        suggestionBoxRef.current &&
        !inputRef.current.contains(event.target) &&
        !suggestionBoxRef.current.contains(event.target)
      ) {
        setSuggestions([]);
        setListVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, suggestionBoxRef]);

  return (
    <SearchContainer style={{ width: "100%" }}>
      <Paper
        elevation={0}
        variant="outlined"
        component="form"
        className="address_input_focus step-0"
        sx={{
          borderRadius: "200px",
          mt: 2,
          mx: "auto",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            height: "67px",
            width: "100%",
          }}
        >
          <InputBase
            startAdornment={
              <InputAdornment sx={{ ml: 2 }} position="start">
                <LocationOnIcon sx={{ color: "#0066EE" }} />
              </InputAdornment>
            }
            sx={{
              ml: 2,
              width: "100%",
              "& .MuiInputBase-input": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "#000",
                width: "90%",
                "&::placeholder": {
                  color: "#6c757d",
                  opacity: 1,
                },
              },
            }}
            type="text"
            inputRef={inputRef}
            value={query}
            onChange={handleInputChange}
            placeholder="Ville, arrondissement, quartier, code postal"
            onFocus={() => {
              if (query) debouncedFetchSuggestions(query);
            }}
          />
        </Box>
        {isMobileIcons ? (
          <Box
            onClick={markerRefFunction}
            sx={{
              width: "fit-content",
              background: "#0066EE",
              borderRadius: "50%",
              padding: "12px",
              display: "flex",
              cursor: "pointer",
              mr: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoSearch color="#fff" size={20} />
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={markerRefFunction}
            sx={{
              px: 6,
              borderRadius: "100px",
              background: "#0066EE",
              fontFamily: `"Inter",sans-serif !important`,
              fontSize: "16px !important",
              fontWeight: "500 !important",
              maxHeight: "56px",
              height: "56px",
              width: "134px",
              marginRight: "5px",
              "&:hover": {
                background: "#0B64DB",
              },
            }}
          >
            Rechercher
          </Button>
        )}
      </Paper>

      <SearchListBox
        ref={suggestionBoxRef}
        style={{
          display: listVisible ? "block" : "none",
          cursor: "pointer",
        }}
        className="searchListBox"
      >
        {loading ? (
          <div
            className="containertype"
            style={{ textAlign: "center", padding: "4%" }}
          >
            <div className="dottype"></div>
            <div className="dottype"></div>
            <div className="dottype"></div>
          </div>
        ) : suggestions.length > 0 ? (
          <List>
            {suggestions.map((suggestion) => (
              <React.Fragment key={suggestion.place_id}>
                <ListItem
                  onClick={() => handleSelectSuggestion(suggestion)}
                  sx={{
                    textTransform: "capitalize",
                    "&:hover .MuiTypography-root": {
                      color: "#0066EE  !important",
                    },
                  }}
                >
                  <ListItemText primary={suggestion.description} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          query && (
            <div style={{ padding: "8px 45px" }}>Adresse introuvable!</div>
          )
        )}
      </SearchListBox>
    </SearchContainer>
  );
};

export default StepOneAutoComplete;
