import React,{useState,useEffect} from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import DataTable from "../BiensEstimes/TableData";
import { formatDate } from "../../utils/utils";

export default function ProjetDeVenteSoumis({ sellProjects }) {
  const [projectDaVente, setProjectDaVente] = useState([]);

  const getProjetDeVenteSoumis = async () => {
    const newData = sellProjects.map((item) => {
      const { address, surface } = item.estimation_id || {};
      const {
        _id,
        creation_date,
        own_property,
        annonce_sell,
        plan_to_sell,
        invest_real_estate,
      } = item;
      const formattedDate = formatDate(creation_date).dateDMY;
      return {
        _id,
        formattedDate,
        address,
        surface,
        own_property,
        annonce_sell,
        plan_to_sell,
        invest_real_estate,
      };
    });
    setProjectDaVente(newData);
  };

  useEffect(() => {
    getProjetDeVenteSoumis();
  }, [sellProjects]);

  const projectDaVenteDataFields = [
    { field: "formattedDate", headerName: "Date", width: 150 },
    { field: "address", headerName: "Adresse", width: 150 },
    { field: "surface", headerName: "Surface", width: 150 },
    { field: "own_property", headerName: "Propriétaire", width: 150 },
    { field: "annonce_sell", headerName: "Type", width: 150 },
    { field: "plan_to_sell", headerName: "Satut", width: 150 },
    { field: "invest_real_estate", headerName: "Neuf", width: 100 },
  ];

  return (
    <Paper
      elevation={3}
      sx={{ p: 4, width: "100%", borderRadius: "13px", mt: 4 }}
    >
      <Typography variant="body2" sx={{ fontWeight: "600", mb: 3 }}>
        Projet de vente soumis ({projectDaVente?.length})
      </Typography>
      <Paper sx={{ width: "100%" }}>
        <DataTable
          filteredData={projectDaVente}
          keysData={projectDaVenteDataFields}
        />
      </Paper>
    </Paper>
  );
}
