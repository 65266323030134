import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "driver.js/dist/driver.css";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { store } from "./Redux/store/store";
import { init as initApm } from "@elastic/apm-rum";
import GlobalToastContainer from "./component/GlobalToastContainer";

// Initialize APM
try {
  const apm = initApm({
    serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
    environment: process.env.REACT_APP_APM_ENVIRONMENT,
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,
    serviceVersion: '1.0.0',
    logLevel: 'info',
    breakdownMetrics: true,
    transactionSampleRate: 1.0,
  });
  apm.startTransaction("App Load", "app-load");
} catch (error) {
  console.error("Failed to initialize APM:", error);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GlobalToastContainer />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
