import moment from "moment/moment";
//  Calculate you monthly installment
export function calculatePriceInstallment(
  homePrice,
  downPayment,
  terms,
  interest,
  taxAssuance
) {
  try {
    if (homePrice > downPayment) {
      const remain = homePrice - downPayment;
      const term = terms * 12;
      const rate = interest / 1200;
      const EMI =
        Math.ceil((remain * rate) / (1 - Math.pow(1 / (1 + rate), term))) || 0;
      const totalPriceInterest = EMI * term + downPayment;

      const taxPerMonth = (remain * taxAssuance) / 1200;
      const taxAssuanceTotal = taxPerMonth * term;
      const EMIWithTax = EMI + taxPerMonth || 0;

      const calculator = {
        taxassuance: taxAssuanceTotal
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        EMI: EMIWithTax.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        totalpriceintrest: totalPriceInterest.toFixed(0),
        // .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        interesttotal: (
          totalPriceInterest -
          (homePrice - downPayment) +
          taxAssuanceTotal
        )
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        status: 200,
        term: terms,
        interest,
        price: homePrice,
        downPayment: parseInt(downPayment),
        taxAssuance,
      };
      // Return response
      return calculator;
    } else {
      return {
        status: 400,
        message:
          "Le montant de la caution ne doit pas dépasser le prix de la propriété",
      };
    }
  } catch (error) {
    // Exception error handling
    console.error(`Error: ${error}`);
    return {
      status: 400,
      message: error,
    };
  }
}

// Date Format For DD/MM/YYYY And DD month YYYY :
export const formatDate = (originalDate) => {
  // Format the date as "DD/MM/YYYY"
  const dateDMY = moment(originalDate).format("DD/MM/YYYY");

  // Format the date in "DD MMMM YYYY" format and set the locale to French
  const date = moment(dateDMY, "DD/MM/YYYY")
    .locale("fr")
    .format("DD MMMM YYYY");

  // Convert the first letter of the month to uppercase
  const formattedDate = date.charAt(0).toUpperCase() + date.slice(1);

  return { dateDMY, formattedDate };
};
// Price Format
export const formatPrice = (price, currencySymbol = " DH") => {
  if (isNaN(price) || typeof price !== "number") {
    return "0.0 DH";
  }
  const priceString = price.toString();
  const groups = priceString.split(/(?=(?:\d{3})+(?:$))/);
  const formattedPrice = groups.join(" ");
  return formattedPrice + currencySymbol;
};

// Function to generate a hash for the image
export const generateImageHash = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
  const hashHex = Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex.substring(0, 16);
};

// Function to Format the Price Area
export function formatPriceArea(
  area,
  decimalPlaces = 0,
  areaSymbol = " DH/m²"
) {
  // Ensure the input is a valid number
  if (isNaN(area) || typeof area !== "number") {
    return "1 DH/m²";
  }

  // Round the number to the specified decimal places
  const roundedArea = Number(area.toFixed(decimalPlaces));

  // Format the area value with commas based on the Indian numbering system
  const formattedArea = roundedArea.toLocaleString("en-IN") + areaSymbol;

  return formattedArea;
}

// Helper function to convert a hex string to Uint8Array
function hexStringToUint8Array(hexString) {
  const byteArray = new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );
  return byteArray;
}

export async function decryptData(encryptedResponse) {
  const { iv, encryptedData, authTag } = encryptedResponse;
  const keyString = process.env.REACT_APP_ENCRYPTION_KEY;

  // Use the helper function instead of Buffer
  const ivBuffer = hexStringToUint8Array(iv);
  const encryptedBuffer = hexStringToUint8Array(encryptedData);
  const authTagBuffer = hexStringToUint8Array(authTag);

  // Combine encrypted data and auth tag for Web Crypto API
  const combinedBuffer = new Uint8Array([...encryptedBuffer, ...authTagBuffer]);

  try {
    // Import the encryption key
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(keyString),
      "AES-GCM",
      false,
      ["decrypt"]
    );
    // Decrypt the data
    const decrypted = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: ivBuffer,
        tagLength: 128,
      },
      cryptoKey,
      combinedBuffer
    );
    return JSON.parse(new TextDecoder().decode(decrypted));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
}

// estimation and publish ads address mapping
export async function estimationAddressMapping(address, postApiRequest) {
  let quartier = address.quartier;
  let city = address.city;
  let province = address.province;
  let region = address.region;
  // api call request
  const res = await postApiRequest(`/api/estimation-address-mapping`, address);
  if (res?.status === 200) {
    if (res.ESdata) {
      quartier = res.ESdata.quartier
        ? res.ESdata.quartier.toLowerCase()
        : res.ESdata.quartier;
      city = res.ESdata.city ? res.ESdata.city.toLowerCase() : res.ESdata.city;
      province = res.ESdata.province
        ? res.ESdata.province.toLowerCase()
        : res.ESdata.province;
      region = res.ESdata.region
        ? res.ESdata.region.toLowerCase()
        : res.ESdata.region;
    } else if (res.matchBy.includes("quartier")) {
      quartier = res.data.quartier;
      city = res.data.city;
      province = res.data.province;
      region = res.data.region;
    } else if (res.matchBy.includes("city")) {
      city = res.data.city;
      province = res.data.province;
      region = res.data.region;
    } else if (res.matchBy.includes("province")) {
      province = res.data.province;
      region = res.data.region;
    } else if (res.matchBy.includes("region")) {
      region = res.data.region;
    }
  } else {
    console.log(res?.message);
  }
  return {
    region,
    city,
    quartier,
    province,
    lat: address.lat,
    long: address.long,
  };
}
