import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import AlertDialog from "./deletePopup";

export default function ViewCategory({
  filteredData,
  keysData,
  open,
  setOpen,
  deleteDataApi,
}) {
  const columns = [];

  keysData?.map((item) => {
    columns.push({
      field: item.field,
      headerName: item.headerName,
      width: item.width,
      renderCell:
        item.field === "type_bien"
          ? (params) => (
              <span style={{ textTransform: "capitalize" }}>
                {params.value}
              </span>
            )
          : item.renderCell || null,
    });
  });

  return (
    <>
      <div>
        <DataGrid
          rows={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </div>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={deleteDataApi}
      />
    </>
  );
}
