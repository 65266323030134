import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      main: '#2f54eb',
    },
    secondary: {
      main: '#20c788',
    }, 
    text: { 
      dark: '#222222',
      lightdark: '#303030',
      danger: '#dc3545',
      success: '#28a745',
      red: '#ff0000',
      white: '#ffffff'
    
    },
  },
  typography: {
    fontFamily: `'Montserrat', sans-serif`,
  },
  
});


theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h2: {  
          fontSize: '1.1rem',  //30px
          fontWeight: '500',
          color: '#4d4a4a',
          lineHeight: '1.2',
          [theme.breakpoints.up('xs')]: { fontSize: '1rem', },
          [theme.breakpoints.up('sm')]: { fontSize: '1rem', },
          [theme.breakpoints.up('md')]: { fontSize: '1rem', },
          [theme.breakpoints.up('lg')]: { fontSize: '1.1rem', },
        },
        h5: {         
          fontSize: '1.25rem',  // 20px
          fontWeight: '700',
          color: '#222222', 
        },
        h6: {         
          fontSize: '1.063rem',
          fontWeight: '700',
          color: '#222222',         
        },

        body2: {
          fontSize: '0.94rem',  //30px
          fontWeight: '700',
          color: '#4d4a4a',  
        },
        subtitle1: {
          fontSize: '.85rem',  //28px
          fontWeight: '400',
          color: '#222222', 
        },
        subtitle2: { 
          fontSize: '.75rem',  //28px
          fontWeight: '300',
          color: '#4d4a4a', 
        },
      }
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {        
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "600",       
          textTransform: 'inherit',
          padding: '0.5rem 1rem',
          '&:hover': {
            backgroundColor: '#20c788'
          }
        },
        outlinedPrimary: {
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "600",
          textTransform: 'inherit',
          padding: '0.4rem 1rem',
          '&:hover': {
            backgroundColor: '#20c788',
            color: '#ffffff',
            borderColor: '#20c788'
          }
        },
      }
    },
  }
})

 


