import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { checkIcon } from "../../../component/ConstantImagesURL";
import { useNavigate } from "react-router-dom";
import StepOtpVerification from "../StepOtpVerification";

export default function StepFinal({
  formData,
  user,
  submitData,
  isEmailVerified,
  setIsEmailVerified,
  sendOtp,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (isEmailVerified) {
      const redirectTimeout = setTimeout(() => {
        navigate("/user/annonces");
      }, 4000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [isEmailVerified]);
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        textAlign: "center",
      }}
    >
      {!isEmailVerified ? (
        <StepOtpVerification
          user={user}
          submitData={submitData}
          onVerificationSuccess={() => setIsEmailVerified(true)}
          sendOtp={sendOtp}
        />
      ) : (
        <>
          <img src={checkIcon} alt="Check Icon" width={"10%"} loading="lazy" />
          <Typography
            sx={{
              fontSize: "clamp(26px, 5vw, 48px) !important",
              fontWeight: 500,
              color: "#0066EE",
              marginTop: "16px",
            }}
          >
            Annonce ajouté
            <br />
            avec succès
          </Typography>
        </>
      )}
    </Container>
  );
}
