import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import CalculezVosMensualites from "./CalculezVosMensualites";
import { postApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";

export default function OuEnEtesVous({
  wishlistId,
  setWishlistId,
  getProjectDaChatData,
  price,
}) {
  const [formData, setFormData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = (formData) => {
    if (!formData.wishlist_id) {
      return "Doit sélectionner une carte";
    }
    if (!formData.purchase_place) {
      return "L'avancement du projet est requis";
    }
    if (!formData.sell_real_estate) {
      return "La vente d'un bien immobilier est obligatoire";
    }
    if (!formData.borrow_money) {
      return "Il est nécessaire d’emprunter de l’argent";
    }
    if (!formData.invest_real_estate) {
      return "Il est nécessaire d’investir dans de l’immobilier neuf";
    }
    return "";
  };
  const submitData = async (e) => {
    const finalProjectData = { ...formData, wishlist_id: wishlistId };
    const errorMessage = validateFormData(finalProjectData);
    if (errorMessage) {
      return toast.error(errorMessage);
    }

    try {
      await postApiRequest("/api/submit-projet-dachat", finalProjectData);
      getProjectDaChatData();
      toast.success("Données soumises avec succès !");
      setFormData({
        borrow_money: "Oui",
        invest_real_estate: "Oui",
      });
      setWishlistId("");
    } catch (error) {
      console.error("Error Submitting Data:", error);
    }
  };
  useEffect(() => {
    setFormData({
      borrow_money: "Oui",
      invest_real_estate: "Oui",
    });
  }, []);
  return (
    <>
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={12} md={5}>
          <Box component="form" sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mt: 2.2 }}>
              <FormLabel
                id="Quel"
                sx={{
                  fontWeight: "500",
                  color: "#4D4A4A",
                  fontSize: "0.9rem",
                  mb: 1,
                }}
              >
                Où en êtes vous de votre projet d’achat ?
              </FormLabel>
              <Select
                id="Quel"
                name="purchase_place"
                value={formData["purchase_place"] || ""}
                onChange={handleChange}
                size="small"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Sélectionnez</em>
                </MenuItem>
                <MenuItem value={"Je m'informe"}>Je m'informe</MenuItem>
                <MenuItem value={"Je débute"}>Je débute</MenuItem>
                <MenuItem value={" Je recherche activement (+de 5 visites)"}>
                  Je recherche activement (+de 5 visites)
                </MenuItem>
                <MenuItem value={"J'ai trouve un bien et ai fait une offre"}>
                  J'ai "un bien et ai fait une offre"e un bien et ai fait une
                  offre
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 3 }}>
              <FormLabel
                id="Envisagez-vous "
                sx={{
                  fontWeight: "500",
                  color: "#4D4A4A",
                  fontSize: "0.9rem",
                  mb: 1,
                }}
              >
                Projetez-vous de vendre un bien immobilier pour financer votre
                achat ?
              </FormLabel>
              <Select
                id="Envisagez-vous"
                name={"sell_real_estate"}
                value={formData["sell_real_estate"] || ""}
                onChange={handleChange}
                size="small"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Sélectionnez</em>
                </MenuItem>
                <MenuItem value={"Oui, j'ai déjà commencé à vendre"}>
                  Oui, j'ai déjà commencé à vendre
                </MenuItem>
                <MenuItem value={"Oui, mais je n'ai pas commencé à vendre"}>
                  Oui, mais je n'ai pas commencé à vendre
                </MenuItem>
                <MenuItem value={"Non"}>Non</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2.2 }}>
              <FormLabel
                id="proprietaire"
                sx={{ fontWeight: "500", color: "#4D4A4A", fontSize: "0.9rem" }}
              >
                Souhaitez-vous emprunter ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="proprietaire"
                name="borrow_money"
                value={formData["borrow_money"] || ""}
                onChange={handleChange}
              >
                <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="Non" control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>

            <FormControl sx={{ textAlign: "left", mt: 3 }}>
              <FormLabel
                id="Pour "
                sx={{ fontWeight: "500", color: "#4D4A4A", fontSize: "0.9rem" }}
              >
                Pour réduire vos impôts souhaitez-vous investir dans
                l’immobilier neuf ?
              </FormLabel>
              <RadioGroup
                row
                name="invest_real_estate"
                value={formData["invest_real_estate"] || ""}
                aria-labelledby="Pour"
                onChange={handleChange}
              >
                <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="Non" control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              sx={{ mt: 2, width: { xs: "100%", sm: "auto" } }}
              onClick={submitData}
            >
              Valider
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <CalculezVosMensualites price={price} />
        </Grid>
      </Grid>
    </>
  );
}
