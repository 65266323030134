import React from "react";

import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";

import { ReactComponent as Home } from "../../assets/images/TopTitle/Home.svg";
import { Link } from "react-router-dom";
export default function DecouvrezNos() {
  return (
    <Box sx={{ mt: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              background: "#f1f5ff",
              p: { xs: 2, sm: 4 },
              borderRadius: "13px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", color: "#4d4a4a", mb: 3 }}
            >
              Découvrez <span style={{ color: "#2f54eb" }}> nos conseils </span>{" "}
              pour vous aider dans vos projets.
            </Typography>
            <Box p={"8px"}>
              <Grid padding={"0px 0px"} container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Link to="https://guide.ki.immo/" target="_blank">
                    <Paper
                      elevation={3}
                      sx={{
                        py: { xs: 3, sm: 4 },
                        px: { xs: 2, sm: 3 },
                        width: "100%",
                        borderRadius: "13px",
                        minHeight: "250px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: ".9rem",
                          fontWeight: "600",
                          color: "#4d4a4a",
                        }}
                      >
                        Le guide pour{" "}
                        <span style={{ color: "#2f54eb", fontWeight: "900" }}>
                          vendre son bien immobilier
                        </span>
                      </Typography>

                      <Divider
                        sx={{
                          my: 2.5,
                          borderWidth: "2px",
                          borderColor: "#4d4a4a",
                          borderRadius: "100px",
                          maxWidth: "40%",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: ".85rem",
                          fontWeight: "400",
                          color: "#4d4a4a",
                        }}
                      >
                        Retrouvez toutes les informations et astuces pour vendre
                        votre bien immobilier en France dans les meilleures
                        conditions.
                      </Typography>
                    </Paper>
                  </Link>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Link to="https://guide.ki.immo/" target="_blank">
                    <Paper
                      elevation={3}
                      sx={{
                        py: { xs: 3, sm: 4 },
                        px: { xs: 2, sm: 3 },
                        width: "100%",
                        borderRadius: "13px",
                        minHeight: "250px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: ".9rem",
                          fontWeight: "600",
                          color: "#4d4a4a",
                        }}
                      >
                        {" "}
                        <span style={{ color: "#2f54eb", fontWeight: "900" }}>
                          {" "}
                          Les 10 critères à vérifier{" "}
                        </span>{" "}
                        avant de faire une offre d’achat
                      </Typography>

                      <Divider
                        sx={{
                          my: 2.5,
                          borderWidth: "2px",
                          borderColor: "#4d4a4a",
                          borderRadius: "100px",
                          maxWidth: "40%",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: ".85rem",
                          fontWeight: "400",
                          color: "#4d4a4a",
                        }}
                      >
                        Consultez la liste des critères à prendre en compte pour
                        bien choisir votre bien immobilier.
                      </Typography>
                    </Paper>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              py: 4,
              px: 2.5,
              width: "100%",
              borderRadius: "13px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", color: "#4d4a4a", mb: 0.5 }}
            >
              Un{" "}
              <span style={{ color: "#2f54eb", fontWeight: "900" }}>
                {" "}
                projet
              </span>{" "}
              à l’esprit ?
            </Typography>
            <Typography>Nous sommes là pour vous aider !</Typography>

            <Home style={{ display: "block", margin: "18px auto" }} />

            <Button
              component={Link}
              to={`${process.env.REACT_APP_WEB_URL}/contactez-nous`}
              target="_blank"
              variant="contained"
            >
              Contactez-nous
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
