import React from "react";
import { ToastContainer } from "react-toastify";
import { BiInfoCircle } from "react-icons/bi";
import { useMediaQuery } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

export default function GlobalToastContainer() {
  const isSmallScreen = useMediaQuery("(max-width:500px)");

  const customStyle = {
    width: isSmallScreen ? "90%" : "",
    top: isSmallScreen ? "75px" : "84px",
    left: isSmallScreen ? "5%" : "",
  };

  return (
    <ToastContainer
      limit={1}
      autoClose={2000}
      icon={({ type }) =>
        type === "success" ? (
          <BiInfoCircle color="#5EC38D" size={30} />
        ) : type === "info" ? (
          <BiInfoCircle color="#3498db" size={30} />
        ) : (
          <BiInfoCircle color="#EC544B" size={30} />
        )
      }
      style={customStyle}
    />
  );
}
