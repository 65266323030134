import React from "react";
import { Route, Routes } from "react-router-dom";
import TableauDeBord from "../pages/Dashboard/TableauDeBord";
import MesBiens from "../pages/BiensEstimes/MesBiens";
import ProjetDeVenteHome from "../pages/ProjetDeVente/ProjetDeVenteHome";
import ProjetDachatHome from "../pages/ProjetDachat/ProjetDachatHome";
import SauvegardesHome from "../pages/Sauvegardes/SauvegardesHome";
import AlertesIndex from "../pages/Alertes/AlertesIndex";
import MonCompteIndex from "../pages/MonCompte/MonCompteIndex";
import Protected from "../Routes/Protected";
import MesAnnonces from "../pages/MesAnnonces";
import MesAnnoncesListing from "../pages/MesAnnonces/MesAnnoncesListing";

export default function WebRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Protected>
            <TableauDeBord />
          </Protected>
        }
      />
      <Route
        path="/user/dashboard"
        element={
          <Protected>
            <TableauDeBord />
          </Protected>
        }
      />
      <Route
        path="/user/mes-alertes"
        element={
          <Protected>
            <AlertesIndex />
          </Protected>
        }
      />
      <Route
        path="/user/mes-publish-annonces"
        element={
          <Protected>
            <MesAnnonces />
          </Protected>
        }
      />
      <Route
        path="/user/annonces"
        element={
          <Protected>
            <MesAnnoncesListing />
          </Protected>
        }
      />
      <Route
        path="/user/mes-biens"
        element={
          <Protected>
            <MesBiens />
          </Protected>
        }
      />
      <Route
        path="/user/mon-projet-de-vente"
        element={
          <Protected>
            <ProjetDeVenteHome />
          </Protected>
        }
      />
      <Route
        path="/user/mon-projet-dachat"
        element={
          <Protected>
            <ProjetDachatHome />
          </Protected>
        }
      />
      <Route
        path="/user/mes-annonces-sauvegardées"
        element={
          <Protected>
            <SauvegardesHome />
          </Protected>
        }
      />
      <Route
        path="/user/mon-compte"
        element={
          <Protected>
            <MonCompteIndex />
          </Protected>
        }
      />
    </Routes>
  );
}
