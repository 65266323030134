import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

import { FaTrashCan } from "react-icons/fa6";
import EditButton from "./EditButton";
import { deleteRequest, getApiRequest } from "../../apiHandler";
import AlertDialog from "../BiensEstimes/deletePopup";
import { toast } from "react-toastify";

export default function AlertesIndex() {
  const CustomBox = styled(Box)(({ theme }) => ({
    background: "#F1F5FF",
    width: "36px",
    height: "36px",
    color: "#2f54eb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    cursor: "pointer",
    "&:hover": {
      width: "36px",
      height: "36px",
      border: "1px solid #2f54eb",
      background: "#2f54eb",
      color: "#F1F5FF",
    },
  }));
  const [alertData, setAlertData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [alertId, setAlertId] = useState("");

  const getAlertData = async () => {
    const GetAlertRes = await getApiRequest("/api/get-user-alerts");
    if (GetAlertRes?.status === 200) {
      setAlertData(GetAlertRes?.response);
    } else {
      toast.error(GetAlertRes?.message);
    }
  };

  useEffect(() => {
    getAlertData();
  }, []);

  const deleteDataApi = async () => {
    if (deleteId) {
      try {
        const deleteRes = await deleteRequest(
          `/api/delete-users-alert/${deleteId}`
        );
        if (deleteRes.status === 200) {
          setOpen(false);
          getAlertData();
          toast.success(deleteRes.message);
        } else {
          toast.error(deleteRes.message);
        }
      } catch (error) {
        toast.error("quelque chose s'est mal passé");
      }
    }
  };

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };
  return (
    <Layout>
      <Box>
        <Paper
          elevation={3}
          sx={{ p: { xs: 2, sm: 4 }, width: "100%", borderRadius: "13px" }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Alertes({alertData?.length})
            </Typography>
            <Button
              component={Link}
              to={`${process.env.REACT_APP_WEB_URL}/annonces`}
              variant="contained"
              color="primary"
              sx={{ px: 2 }}
            >
              Créer une alerte
            </Button>
          </Stack>
          <Grid container spacing={3} sx={{ my: 1 }}>
            {alertData &&
              alertData?.map((item) => {
                const searchData = JSON.parse(item.search_data);
                return (
                  <Grid item xs={12} sm={6} md={4} key={item._id}>
                    <Paper
                      elevation={2}
                      sx={{
                        py: { xs: 2, sm: 4 },
                        px: { xs: 1.5, sm: 3 },
                        width: "100%",
                        borderRadius: "13px",
                        maxHeight: "316px",
                        minHeight: "316px",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <EditButton
                          searchData={searchData}
                          item={item}
                          getAlertData={getAlertData}
                          alertId={alertId}
                          setAlertId={setAlertId}
                        />
                        <CustomBox onClick={() => handleDeleteOpen(item._id)}>
                          <FaTrashCan />
                        </CustomBox>
                      </Stack>
                      <Typography
                        variant="body2"
                        sx={{
                          my: 3,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis ",
                        }}
                      >
                        {item?.alert_title}
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{ my: 2, gap: 0.6 }}
                        alignItems="center"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                      >
                        {searchData.ads_type && (
                          <Chip
                            sx={{
                              borderRadius: "5px",
                              background: "#F3F3F3",
                              color: "#4D4A4A",
                              fontWeight: "300",
                            }}
                            label={
                              searchData.ads_type === "rent"
                                ? "Louer"
                                : "Vendre"
                            }
                          />
                        )}
                        {searchData?.property_type?.length > 0 && (
                          <Chip
                            sx={{
                              borderRadius: "5px",
                              background: "#F3F3F3",
                              color: "#4D4A4A",
                              fontWeight: "300",
                            }}
                            label={
                              Array.isArray(searchData?.property_type)
                                ? searchData.property_type.join(",")
                                : ""
                            }
                          />
                        )}
                        {searchData?.areamin != 0 && searchData?.areamax != 0 && (
                          <Chip
                            sx={{
                              borderRadius: "5px",
                              background: "#F3F3F3",
                              color: "#4D4A4A",
                              fontWeight: "300",
                            }}
                            label={
                              searchData?.areamin +
                              "-" +
                              searchData?.areamax +
                              " m²"
                            }
                          />
                        )}
                        {searchData?.rooms?.length > 0 && (
                          <Chip
                            sx={{
                              borderRadius: "5px",
                              background: "#F3F3F3",
                              color: "#4D4A4A",
                              fontWeight: "300",
                            }}
                            label={
                              Array.isArray(searchData?.rooms)
                                ? searchData.rooms.join(",") + " Pièces"
                                : ""
                            }
                          />
                        )}
                        {searchData?.pricemax != 0 &&
                          searchData?.pricemin != 0 && (
                            <Chip
                              sx={{
                                borderRadius: "5px",
                                background: "#F3F3F3",
                                color: "#4D4A4A",
                                fontWeight: "300",
                              }}
                              label={
                                searchData.pricemin +
                                "-" +
                                searchData.pricemax +
                                " MAD"
                              }
                            />
                          )}
                      </Stack>
                      <Box textAlign="center" sx={{ mt: 2 }}>
                        <Button
                          variant="outlined"
                          component={Link}
                          to={`${process.env.REACT_APP_WEB_URL}/annonces-search-results/${item._id}`}
                        >
                          Voir les résultats
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
      </Box>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={deleteDataApi}
      />
    </Layout>
  );
}
