import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import OuEnEtesVous from "./OuEnEtesVous";
import ProjetDachatSoumis from "./ProjetDachatSoumis";
import ProjectDaChatCards from "./ProjectDaChatCards";
import { getApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";

export default function ProjetDachatHome() {
  const [wishlist, setWishlist] = useState([]);
  const [wishlistId, setWishlistId] = useState("");
  const [purchaseProjects, setPurchaseProjects] = useState([]);
  const [price, setPrice] = useState(1000);

  const getProjectDaChatData = async () => {
    const projectDatChat = await getApiRequest("/api/get-projet-dachat");
    if (projectDatChat?.status === 200) {
      setWishlist(projectDatChat?.response?.wishlist);
      setPurchaseProjects(projectDatChat?.response?.purchase_projects);
    } else {
      toast.error(projectDatChat?.message);
    }
  };

  useEffect(() => {
    getProjectDaChatData();
  }, []);
  return (
    <Layout>
      <Box>
        <Paper
          elevation={3}
          sx={{ p: { xs: 2, sm: 4 }, width: "100%", borderRadius: "13px" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Sélectionnez votre bien parmi les annonces sauvegardées (
              {wishlist?.length})
            </Typography>
            <a href={`${process.env.REACT_APP_WEB_URL}/annonces`}>
              <Button
                variant="contained"
                color="primary"
                sx={{ px: 2, width: { xs: "100%", sm: "auto" } }}
              >
                Nouvelle recherche
              </Button>
            </a>
          </Stack>

          {/* Card */}
          <ProjectDaChatCards
            wishlist={wishlist}
            wishlistId={wishlistId}
            setWishlistId={setWishlistId}
            getProjectDaChatData={getProjectDaChatData}
            setPrice={setPrice}
          />
          {/*  */}

          {/* FORM */}
          <OuEnEtesVous
            wishlistId={wishlistId}
            setWishlistId={setWishlistId}
            getProjectDaChatData={getProjectDaChatData}
            price={price}
          />
          {/*  */}
        </Paper>

        {/* TABLE  */}
        <ProjetDachatSoumis purchaseProjects={purchaseProjects} />
        {/*   */}
      </Box>
    </Layout>
  );
}
