import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import EtesVous from "./EtesVous";
import ProjetDeVenteSoumis from "./ProjetDeVenteSoumis";
import { getApiRequest } from "../../apiHandler";
import ProjectDeVenteCards from "./ProjectDeVenteCards";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/reducer/reducer";
import { toast } from "react-toastify";

export default function ProjetDeVenteHome() {
  const [estimation, setEstimation] = useState([]);
  const [estimationId, setEstimationId] = useState("");
  const [sellProjects, setSellProjects] = useState([]);
  const user = useSelector(selectUser);

  const getProjetDeVenteData = async () => {
    const projetDeVente = await getApiRequest("/api/get-projet-de-vente");

    if (projetDeVente?.status === 200) {
      setEstimation(projetDeVente?.response?.estimation);
      setSellProjects(projetDeVente?.response?.sell_projects);
    } else {
      toast.error(projetDeVente?.message);
    }
  };

  useEffect(() => {
    getProjetDeVenteData();
  }, []);

  return (
    <Layout>
      <Box>
        <Paper
          elevation={3}
          sx={{ p: { xs: 2, sm: 4 }, width: "100%", borderRadius: "13px" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Sélectionnez votre bien parmi les biens estimés (
              {estimation?.length})
            </Typography>
            <a href={`${process.env.REACT_APP_WEB_URL}/estimation-immobiliere`}>
              <Button
                variant="contained"
                color="primary"
                sx={{ px: 2, width: { xs: "100%", sm: "auto" } }}
              >
                Estimer un nouveau bien
              </Button>
            </a>
          </Stack>

          {/* Cards */}
          <ProjectDeVenteCards
            estimation={estimation}
            estimationId={estimationId}
            setEstimationId={setEstimationId}
          />
          {/*  */}

          {/* Form */}
          <EtesVous
            estimationId={estimationId}
            setEstimationId={setEstimationId}
            getProjetDeVenteData={getProjetDeVenteData}
          />
          {/*  */}
        </Paper>

        {/* Table */}
        <ProjetDeVenteSoumis sellProjects={sellProjects} />
        {/*  */}
      </Box>
    </Layout>
  );
}
