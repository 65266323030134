import React, { useEffect, useRef } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import InformationsPrincipalesDuBien from "./InformationsPrincipalesDuBien";
import InformationsSupplémentaires from "./InformationsSupplémentaires";
import InformationsDeContact from "./InformationsDeContact";
import InformationDeLannonce from "./InformationDeLannonce";
import AjouterUneVisiteVirtuelle from "./AjouterUneVisiteVirtuelle";
import { toast } from "react-toastify";
import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
} from "../../../component/ConstantImagesURL";
import { startDriverTour } from "../../../component/TourGuide";

const logoImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
];

const itemArray = [
  "Appartement",
  "Maison",
  "Terrain",
  "Immeuble",
  "Parking",
  "Bureau",
  "Local Commercial",
  "Fonds de Commerce",
  "Studio",
  "Villa",
  "Riad",
];

const typeOfNews = ["Vente", "Location"];

const excludedTypes = ["terrain", "parking"];

export default function StepTwo({
  formData,
  setFormData,
  handleClick,
  setRef,
  scrollToElement,
  user,
}) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (user?.email_verification === false) {
      const steps = [
        {
          element: ".ads-type-step",
          popover: {
            title: "Type d'annonce",
            description:
              "Sélectionnez si l'annonce est à vendre ou à louer en cliquant sur l'option appropriée.",
            align: "top",
          },
        },
        {
          element: ".property-type-step",
          popover: {
            title: "Type de propriété",
            description:
              "Sélectionnez le type de propriété parmi les options disponibles en cliquant sur l'une d'elles.",
            align: "center",
          },
        },
        {
          element: ".property-main-info-header",
          popover: {
            title: "Détails clés de la propriété",
            description:
              "Cette section vous permet de remplir les détails clés de votre propriété. Compléter tous les champs applicables garantit une meilleure visibilité et un appariement précis avec les acheteurs ou locataires potentiels.",
            align: "center",
          },
        },
        {
          element: ".additional-property-info",
          popover: {
            title: "Caractéristiques supplémentaires",
            description:
              "Cette section vous aide à mettre en avant les caractéristiques supplémentaires de votre propriété. Fournir des informations précises et détaillées améliore l'attrait et la visibilité de votre annonce pour les acheteurs ou locataires potentiels.",
            align: "center",
          },
        },
        {
          element: ".contact_information",
          popover: {
            title: "Informations de contact",
            description:
              "Fournissez vos informations de contact ici, y compris votre numéro de téléphone, pour faciliter le contact des parties intéressées. Vous pouvez choisir de masquer votre numéro de téléphone de l'annonce pour des raisons de confidentialité tout en restant joignable par e-mail.",
            align: "center",
          },
        },
        {
          element: ".information_annonce",
          popover: {
            title: "Détails de l'annonce",
            description:
              "Cette section est cruciale pour définir les détails de votre annonce. Fournissez un titre clair, un prix précis et une description détaillée pour maximiser la visibilité et attirer les acheteurs ou locataires potentiels. Utilisez l'outil d'IA pour générer une description convaincante si nécessaire.",
            align: "center",
          },
        },
        {
          element: ".virtual-visit-info",
          popover: {
            title: "Visite virtuelle",
            description:
              "Ajouter une visite virtuelle à votre annonce augmente sa visibilité jusqu'à 60 % et aide à vendre les propriétés jusqu'à 3 fois plus rapidement. Fournissez un lien vers une visite 3D existante ou contactez-nous pour en créer une pour vous.",
            align: "center",
          },
        },
        {
          element: ".steptwo-prv-btn",
          popover: {
            title: "Étape précédente",
            description:
              "Cliquez sur 'Retour' pour revenir à l’étape précédente.",
            align: "center",
          },
        },
        {
          element: ".steptwo-nxt-btn",
          popover: {
            title: "Étape suivante",
            description:
              "Cliquez sur 'Suivant' pour revenir à l’étape suivante.",
            align: "center",
          },
        },
      ];
      setTimeout(() => {
        startDriverTour(steps, "step-two", "publishAds-steps-completed");
      }, 1000);
    }
  }, [user]);

  const handleItemClick = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    handleClick("prev");
  };

  const validateFormData = () => {
    if (!formData.ads_type) {
      toast.error("Le Type d’annonce est un champ obligatoire.");
      scrollToElement("ads_type");
      return false;
    } else if (!formData.property_type) {
      toast.error("Le Type Bein est un champ obligatoire.");
      scrollToElement("property_type");
      return false;
    } else if (!formData.area) {
      toast.error("La Surface affichée est un champ obligatoire.");
      scrollToElement("area");
      return false;
    } else if (formData.property_type === "terrain" && !formData.zoning) {
      toast.error("Le zonage est un champ obligatoire.");
      scrollToElement("zoning");
      return false;
    } else if (
      formData.property_type !== "parking" &&
      formData.property_type !== "terrain" &&
      formData.property_type !== "bureau" &&
      formData.property_type !== "local Commercial" &&
      formData.property_type !== "fonds de Commerce" &&
      formData.property_type !== "studio" &&
      !formData.rooms
    ) {
      toast.error("Les Chambres sont un champ obligatoire.");
      scrollToElement("rooms");
      return false;
    } else if (
      formData.property_type !== "parking" &&
      formData.property_type !== "terrain" &&
      !formData.bathrooms
    ) {
      toast.error("La Salle de bain est un champ obligatoire.");
      scrollToElement("bathrooms");
      return false;
    } else if (!formData.phone_code && !formData.phone_number) {
      toast.error("Le Téléphone est un champ obligatoire.");
      scrollToElement("phone_number");
      return false;
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      toast.error("Le numéro de téléphone doit comporter 10 chiffres.");
      scrollToElement("phone_number");
      return false;
    } else if (!formData.price) {
      toast.error("Le Prix est un champ obligatoire.");
      scrollToElement("price");
      return false;
    } else if (!formData.title) {
      toast.error("Le Titre de l’annonce est un champ obligatoire.");
      scrollToElement("title");
      return false;
    } else if (!formData.description) {
      toast.error("La Description est un champ obligatoire.");
      scrollToElement("description");
      return false;
    } else if (formData.floors_level > formData.floors_count) {
      toast.error(
        "L'étage ne peut pas dépasser le nombre d'étages du bâtiment"
      );
      scrollToElement("floors");
      return false;
    } else if (
      formData.virtual_visit &&
      !/^(https:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?.*$/.test(
        formData.virtual_visit
      )
    ) {
      toast.error("Le lien est invalide.");
      scrollToElement("virtual_visit");
      return false;
    } else if (!formData.description) {
      toast.error("La Description est un champ obligatoire.");
      scrollToElement("description");
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateFormData()) {
      handleClick("next");
    }
  };

  return (
    <>
      <Container
        sx={{
          width: "90%",
          gap: 5,
          mt: 4,
          mb: 12,
        }}
        className="stepTwo"
      >
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8}>
          <Box>
            <Typography
              variant="body1"
              fontSize={32}
              fontWeight={700}
              color={"#263238"}
            >
              Détails de l’annonce
            </Typography>
            <Typography variant="body1" fontSize={16} color={"#263238"} mt={1}>
              Ajouter plus de détails sur votre annonce pour un maximum de
              visibilité
            </Typography>
          </Box>
          <Box mt={6} className="ads-type-step">
            <Typography
              variant="body1"
              fontSize={24}
              fontWeight={600}
              color={"#263238"}
            >
              Type d’annonce
            </Typography>
            <Box display={"flex"} gap={1} mt={3} ref={setRef("ads_type")} >
              {typeOfNews.map((type, index) => (
                <Box
                  key={index}
                  width={260}
                  height={160}
                  bgcolor={
                    formData.ads_type === (index === 0 ? "sale" : "rent")
                      ? "#0066EE"
                      : "#FFFFFF"
                  }
                  onClick={() =>
                    setFormData({
                      ...formData,
                      ads_type: index === 0 ? "sale" : "rent",
                    })
                  }
                  border={0.5}
                  borderColor={"#FFFFFF"}
                  borderRadius={8}
                  gap={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    transition: "border-color 0.2s ease",
                    width: { xs: "100%", sm: 180 },
                    height: { xs: 55, sm: 55, md: 55, lg: 55 },
                    cursor: "pointer",
                    "&:hover": {
                      borderColor: "#0066EE",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={500}
                    display={"flex"}
                    color={
                      formData.ads_type === (index === 0 ? "sale" : "rent")
                        ? "#FFFFFF"
                        : "#263238"
                    }
                  >
                    {type}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="property-type-step">
            <Box textAlign="left" mt={6}>
              <Typography
                variant="body1"
                fontSize={24}
                fontWeight={600}
                color={"#263238"}
              >
                Type de bien
              </Typography>
            </Box>
            <Box ref={setRef("property_type")} sx={{ width: "100%", mt: 3 }}>
              <Grid container spacing={4} className="property-type-step">
                {logoImages.map((logo, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Box
                      bgcolor={
                        formData.property_type ===
                        itemArray[index].toLowerCase()
                          ? "#0066EE"
                          : "#FFFFFF"
                      }
                      border={0.5}
                      borderColor={"#FFFFFF"}
                      borderRadius={8}
                      gap={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        transition: "border-color 0.2s ease",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          md: "100%",
                        },
                        height: { xs: 55, sm: 55, md: 55, lg: 55 },
                        cursor: "pointer",
                        "&:hover": {
                          borderColor: "#0066EE",
                        },
                      }}
                      onClick={() =>
                        handleItemClick(
                          "property_type",
                          itemArray[index].toLowerCase()
                        )
                      }
                    >
                      <img
                        src={logo}
                        alt={`Logo ${index + 1}`}
                        style={{
                          filter:
                            formData.property_type ===
                            itemArray[index].toLowerCase()
                              ? "brightness(0) invert(1)"
                              : "none",
                          width: "22px",
                          height: "22px",
                          display: "block",
                        }}
                        loading="lazy"
                      />
                      <Typography
                        variant="body1"
                        fontSize={16}
                        fontWeight={500}
                        color={
                          formData.property_type ===
                          itemArray[index].toLowerCase()
                            ? "#FFFFFF"
                            : "#263238"
                        }
                        sx={{ ml: 1 }}
                      >
                        {itemArray[index]}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box className="property-main-info-header">
            <InformationsPrincipalesDuBien
              formData={formData}
              setFormData={setFormData}
              handleItemClick={handleItemClick}
              setRef={setRef}
            />
          </Box>
          <Box>
            {!excludedTypes.includes(formData["property_type"]) && (
              <Box className={"additional-property-info"}>
                <InformationsSupplémentaires
                  formData={formData}
                  setFormData={setFormData}
                  handleItemClick={handleItemClick}
                  setRef={setRef}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8} mt={3}>
          <InformationsDeContact
            formData={formData}
            handleItemClick={handleItemClick}
            setRef={setRef}
          />
        </Box>
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8} mt={3}>
          <InformationDeLannonce
            formData={formData}
            setFormData={setFormData}
            handleItemClick={handleItemClick}
            setRef={setRef}
          />
        </Box>
        <Box
          bgcolor="#EDF1F5"
          borderRadius={3}
          px={5}
          py={4}
          mt={3}
          className="virtual-visit-info"
        >
          <AjouterUneVisiteVirtuelle
            formData={formData}
            setFormData={setFormData}
            handleItemClick={handleItemClick}
            setRef={setRef}
          />
        </Box>
      </Container>
      {/* Button */}
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Button
            className="steptwo-prv-btn"
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleBack}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            className="steptwo-nxt-btn"
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleNext}
          >
            Suivant
          </Button>
        </Box>
      </Box>
      {/* Button */}
    </>
  );
}
