import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import { putRequest } from "../../apiHandler";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUserPreferences } from "../../Redux/reducer/reducer";

const preferencesList = [
  {
    name: "updating_price_myproperty",
    label: "La mise à jour du prix de mon bien (mensuelle)",
  },
  {
    name: "saved_searches_and_ad_alerts",
    label: "Recherches sauvegardées et alertes d’annonces",
  },
  {
    name: "real_estate_tips_and_advice_and_new_products",
    label: "Des astuces et conseils en immobilier et les nouveautés produits",
  },
  {
    name: "personalized_messages_from_real_estate_agencies",
    label: "Des messages personnalisés d’agences immobilières",
  },
  {
    name: "partner_offers",
    label: "Des offres de partenaires",
  },
  {
    name: "surveys",
    label: "Des sondages",
  },
  {
    name: "i_do_not_wish_to_receive_messages_from_KI",
    label: "Je ne souhaite pas recevoir de messages de la part de KI",
  },
  // Add other preferences with similar structure
];
export default function Preferences() {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedPreferences((prevPreferences) => [...prevPreferences, name]);
    } else {
      setSelectedPreferences((prevPreferences) =>
        prevPreferences.filter((preference) => preference !== name)
      );
    }
  };

  useEffect(() => {
    if (userData.preference) {
      setSelectedPreferences(userData.preference);
    }
  }, [userData.preference]);

  const savePreferences = async () => {
    const preferencesObject = {
      preferences: selectedPreferences,
    };
    const preferenceRes = await putRequest(
      "/api/update-users-preferences",
      preferencesObject
    );
    if (preferenceRes?.status === 200) {
      dispatch(updateUserPreferences(selectedPreferences));
      toast.success(
        "Les préférences utilisateur ont été mises à jour avec succès."
      );
    }
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          mt: { xs: 1, md: 6 },
          p: { xs: 3, sx: 6 },
          width: "100%",
          borderRadius: "13px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "#00156A", fontWeight: "700" }}
        >
          Mes abonnements
        </Typography>

        <Box component="form">
          <Typography
            variant="body1"
            sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
          >
            J’accepte de recevoir :
          </Typography>
          {preferencesList.map((preference) => (
            <div key={preference.name} style={{ marginRight: "16px" }}>
              <FormControlLabel
                key={preference.name}
                control={
                  <Checkbox
                    checked={selectedPreferences.includes(preference.name)}
                    name={preference.name}
                    onChange={handleCheckboxChange}
                  />
                }
                label={preference.label}
              />
            </div>
          ))}

          <Box sx={{ textAlign: "right", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              onClick={savePreferences}
            >
              Enregistrer
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
