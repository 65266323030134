import React from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

import { FaRegEye } from "react-icons/fa";
import { PiEyeClosedLight } from "react-icons/pi";
import { useForm } from "react-hook-form";
import { postApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    current_password: yup
      .string()
      .required("Le mot de passe actuel est requis"),
    new_password: yup.string().required("Un nouveau mot de passe est requis"),
    confirm_password: yup
      .string()
      .required("Confirmer que le mot de passe est requis"),
  })
  .required();
function ChangePassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    default: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (value) => {
    const changePass = await postApiRequest(
      "/api/update-users-password",
      value
    );
    if (changePass?.status === 200) {
      toast.success(changePass?.message);
      reset();
    } else if (Array.isArray(changePass?.message)) {
      changePass?.message?.map((val) => {
        toast.error(val, {});
      });
    } else {
      toast.error(changePass?.message);
    }
  };

  return (
    <div>
      <Paper
        elevation={3}
        sx={{
          mt: { xs: 1, md: 6 },
          p: { xs: 3, sx: 6 },
          width: "100%",
          borderRadius: "13px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "#00156A", fontWeight: "700" }}
        >
          Mon mot de passe
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Ancien mot de passe
            </Typography>

            <OutlinedInput
              placeholder="********"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              size="small"
              {...register("current_password")}
              error={!!errors?.current_password}
              helperText={errors?.current_password?.message}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <FaRegEye /> : <PiEyeClosedLight />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Nouveau mot de passe
            </Typography>
            <OutlinedInput
              placeholder="********"
              id="Nouveapasse"
              type={showPassword ? "text" : "password"}
              size="small"
              {...register("new_password")}
              error={!!errors?.new_password}
              helperText={errors?.new_password?.message}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Nouveau mot de passe"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <FaRegEye /> : <PiEyeClosedLight />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Confirmation du nouveau mot de passe
            </Typography>
            <OutlinedInput
              placeholder="********"
              id="Confirmatiopasse"
              type={showPassword ? "text" : "password"}
              size="small"
              {...register("confirm_password")}
              error={!!errors?.confirm_password}
              helperText={errors?.confirm_password?.message}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Confirmation du nouveau mot de passe"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <FaRegEye /> : <PiEyeClosedLight />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Box sx={{ textAlign: "right", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              type="submit"
            >
              Mettre à jour
            </Button>
          </Box>
          {/* <SupprimerMonCompte/> */}
        </Box>
      </Paper>
    </div>
  );
}

export default ChangePassword;
