import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { calculatePriceInstallment } from "../../utils/utils";
import CircularPie from "./CircularPie";

export default function CalculatorAnnounceDetailPage({
  price: originalPrice,
  check,
}) {
  const [calculateRes, setCalculateRes] = useState({});

  // Input values
  const [homePrice, setHomePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [term, setTerm] = useState("25");
  const [interest, setInterest] = useState("");
  const [taxAssuance, setTaxAssuance] = useState("");

  // State variables to track empty inputs
  const [homePriceEmpty, setHomePriceEmpty] = useState(true);
  const [downPaymentEmpty, setDownPaymentEmpty] = useState(true);
  const [interestEmpty, setInterestEmpty] = useState(true);
  const [taxAssuanceEmpty, setTaxAssuanceEmpty] = useState(true);

  // useEffect to handle initial calculation based on originalPrice
  useEffect(() => {
    if (originalPrice) {
      const response = calculatePriceInstallment(
        originalPrice,
        originalPrice / 12,
        25,
        2,
        0.3
      );
      if (response.status === 200) {
        setCalculateRes(response);
        setHomePrice(response.price.toString());
        setDownPayment(response.downPayment.toString());
        setTerm(response.term.toString());
        setInterest(response.interest.toString());
        setTaxAssuance(response.taxAssuance.toString());
        setHomePriceEmpty(false);
        setDownPaymentEmpty(false);
        setInterestEmpty(false);
        setTaxAssuanceEmpty(false);
      }
    } else {
      // Default values if originalPrice is not provided
      if (!check) {
        const response = calculatePriceInstallment(4710000, 47100, 25, 2, 0.3);
        if (response.status === 200) {
          setCalculateRes(response);
          setHomePrice(response.price.toString());
          setDownPayment(response.downPayment.toString());
          setHomePriceEmpty(false);
          setDownPaymentEmpty(false);
        }
      }
    }
  }, [originalPrice]);

  // Function to validate if input is empty
  const validateEmpty = (input) => {
    return input.trim() === "";
  };

  // Calculate Price useEffect based on input changes
  useEffect(() => {
    const response = calculatePriceInstallment(
      parseFloat(homePrice),
      parseFloat(downPayment),
      parseFloat(term),
      parseFloat(interest),
      parseFloat(taxAssuance)
    );
    if (response.status === 200) {
      setCalculateRes(response);
    }
  }, [homePrice, downPayment, term, interest, taxAssuance]);

  return (
    <Container maxWidth="md">
      <Typography
        variant="body1"
        color={"#263238"}
        sx={{
          textAlign: "center",
          pb: 2,
          fontWeight: 700,
        }}
      >
        Calculez Vos Mensualités
        {/* <span style={{ color: "#0066EE" }}>Mensualités</span> */}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          borderRadius: "25px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          maxWidth: { xs: "100%", sm: "100%" },
          mx: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} justifyContent={"center"} display={"grid"}>
            <FormControl sx={{ m: 0.8 }} variant="outlined" >
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(38, 50, 56, 0.75)",
                  mb: 0.5,
                }}
              >
                Prix du bien<span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <OutlinedInput
                placeholder="550 000"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  setHomePrice(e.target.value);
                  setHomePriceEmpty(validateEmpty(e.target.value));
                }}
                onInput={(e) => {}}
                value={homePrice}
                error={homePriceEmpty}
                sx={{
                  borderRadius: "22.5px",
                  border: homePriceEmpty ? "0px solid red" : "none",
                  ".MuiOutlinedInput-input": {
                    padding: "8.5px 14px",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">DH</InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ m: 0.8 }} variant="outlined">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(38, 50, 56, 0.75)",
                  mb: 0.5,
                }}
              >
                Apport<span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <OutlinedInput
                placeholder="144 000"
                size="small"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  setDownPayment(e.target.value);
                  setDownPaymentEmpty(validateEmpty(e.target.value));
                }}
                value={downPayment}
                error={downPaymentEmpty}
                sx={{
                  borderRadius: "22.5px",
                  border: downPaymentEmpty ? "0px solid red" : "none",
                }}
                endAdornment={
                  <InputAdornment position="end">DH</InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(38, 50, 56, 0.75)",
                  mb: 0.5,
                }}
              >
                Durée de votre emprunt
                <span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <TextField
                select
                size="small"
                placeholder="25 ans"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                SelectProps={{
                  style: {
                    width: "100%",
                    borderRadius: "22.5px",
                    border: "none",
                  },
                  MenuProps: {
                    PaperProps: {
                      style: {
                        borderRadius: "22.5px",
                        border: "none",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="7">7 ans</MenuItem>
                <MenuItem value="10">10 ans</MenuItem>
                <MenuItem value="15">15 ans</MenuItem>
                <MenuItem value="20">20 ans</MenuItem>
                <MenuItem value="25">25 ans</MenuItem>
                <MenuItem value="30">30 ans</MenuItem>
              </TextField>
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(38, 50, 56, 0.75)",
                  mb: 0.5,
                }}
              >
                Taux d’intérêt<span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <OutlinedInput
                placeholder="2"
                size="small"
                type="number"
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9.]/g, "");
                  if (value === "" || parseFloat(value) <= 100) {
                    setInterest(e.target.value);
                    setInterestEmpty(validateEmpty(e.target.value));
                  }
                }}
                value={interest}
                error={interestEmpty}
                sx={{
                  borderRadius: "22.5px",
                  border: interestEmpty ? "0px solid red" : "none",
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(38, 50, 56, 0.75)",
                  mb: 0.5,
                }}
              >
                Taux d’assurance<span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <OutlinedInput
                placeholder="2"
                size="small"
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9.]/g, "");
                  if (value === "" || parseFloat(value) <= 100) {
                    setTaxAssuance(e.target.value);
                    setTaxAssuanceEmpty(validateEmpty(e.target.value));
                  }
                }}
                value={taxAssuance}
                error={taxAssuanceEmpty}
                sx={{
                  borderRadius: "22.5px",
                  border: taxAssuanceEmpty ? "0px solid red" : "none",
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7} justifyContent="center" alignItems="center">
            <Box className="flexcenter" sx={{ height: "100%" }}>
              <CircularPie
                totalPrice={calculateRes["EMI"] || 0}
                loanAmount={calculateRes["price"] - calculateRes["downPayment"]}
                totalInterest={calculateRes["totalpriceintrest"]}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
