// utils/validateImage.js
import * as mobilenet from "@tensorflow-models/mobilenet";
import "@tensorflow/tfjs";

const relevantCategories = new Set([
  "building", "house", "apartment", "interior", "living", "bedroom",
  "bathroom", "kitchen", "office", "land", "garden", "yard", "pool",
  "balcony", "terrace", "garage", "lobby", "commercial", "industrial",
  "real estate", "property", "construction", "bath", "door", "home",
  "shoji", "shower", "basin", "wardrobe", "bed", "palace", "dining"
]);

// Cache the model globally
let modelCache = null;

export const validateImage = async (file) => {
  try {
    // Basic file validation first
    if (!file.type.startsWith('image/')) {
      return { isValid: false, tags: [] };
    }
    // Resize image before processing
    const img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    await img.decode();
    // Create a canvas to resize the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Resize to smaller dimensions for faster processing
    const MAX_SIZE = 300;
    let width = img.width;
    let height = img.height;
    
    if (width > height) {
      if (width > MAX_SIZE) {
        height = height * (MAX_SIZE / width);
        width = MAX_SIZE;
      }
    } else {
      if (height > MAX_SIZE) {
        width = width * (MAX_SIZE / height);
        height = MAX_SIZE;
      }
    }
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    // Load or get cached model
    if (!modelCache) {
      modelCache = await mobilenet.load({
        version: 2,
        alpha: 0.5  // Use a smaller/faster model
      });
    }
    const predictions = await modelCache.classify(canvas, 5); // Limit to top 5 predictions
    // Quick check for relevant categories
    const detectedTags = [];
    for (const prediction of predictions) {
      const predictionLower = prediction.className.toLowerCase();
      for (const category of relevantCategories) {
        if (predictionLower.includes(category)) {
          detectedTags.push(prediction.className);
          break;
        }
      }
    }
    URL.revokeObjectURL(img.src);
    return {
      isValid: detectedTags.length > 0,
      tags: detectedTags,
    };
  } catch (error) {
    console.error("Error during image validation:", error);
    return {
      isValid: false,
      tags: [],
    };
  }
};