import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Stack,
  Typography,
  Container,
  AppBar,
  Divider,
} from "@mui/material";

// import logo from '../logo.svg'

import { ReactComponent as Logo } from "../logo.svg";

import { AiOutlineMenuFold } from "react-icons/ai";

import { IoIosCloseCircleOutline } from "react-icons/io";
import Sidebar from "../component/Sidebar";
import UserAction from "../component/UserAction";

import { ReactComponent as Tableau } from "../assets/images/HeadTitle/Tableau.svg";
import { ReactComponent as Alertes } from "../assets/images/HeadTitle/Alertes.svg";

import { ReactComponent as BiensEstimes } from "../assets/images/HeadTitle/BiensEstimes.svg";
import { ReactComponent as ProjectDeVente } from "../assets/images/HeadTitle/ProjectDeVente.svg";
import { ReactComponent as ProjectDachat } from "../assets/images/HeadTitle/ProjectDachat.svg";
import { ReactComponent as Sauvegardees } from "../assets/images/HeadTitle/sauvegardees.svg";
import { ReactComponent as MonCompte } from "../assets/images/HeadTitle/MonCompte.svg";
import { ReactComponent as MesAnnounces } from "../assets/images/HeadTitle/MesAnnounces.svg";
const drawerWidth = 270;

export default function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();

  const [headerTitle, setHeaderTitle] = useState({
    title: "Tableau de bord",
    icons: Tableau,
  });

  useEffect(() => {
    if (location.pathname === "/user/mes-alertes") {
      setHeaderTitle({
        title: "Alertes",
        icons: Alertes,
      });
    } else if (location.pathname === "/user/mes-publish-annonces") {
      setHeaderTitle({
        title: "Publier une annonce",
        icons: MesAnnounces,
      });
    } else if (location.pathname === "/user/mes-biens") {
      setHeaderTitle({
        title: "Biens estimés",
        icons: BiensEstimes,
      });
    } else if (location.pathname === "/user/mon-projet-de-vente") {
      setHeaderTitle({
        title: "Projet de vente",
        icons: ProjectDeVente,
      });
    } else if (location.pathname === "/user/mon-projet-dachat") {
      setHeaderTitle({
        title: "Projet d’achat",
        icons: ProjectDachat,
      });
    } else if (location.pathname === "/user/mes-annonces-sauvegardées") {
      setHeaderTitle({
        title: "Annonces sauvegardées",
        icons: Sauvegardees,
      });
    } else if (location.pathname === "/user/mon-compte") {
      setHeaderTitle({
        title: "Mon compte",
        icons: MonCompte,
      });
    } else if (location.pathname === "/user/annonces") {
      setHeaderTitle({
        title: "Mes annonces",
        icons: MesAnnounces,
      });
    }
  }, [location]);

  return (
    <Box sx={{ display: { xs: "block", sm: "flex" } }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: "999",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #f0f5ff",
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Container
            maxWidth="lg"
            sx={{ px: { xs: "0rem", lg: "2rem", xl: "4rem" } }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              sx={{ width: "100%" }}
              alignItems="center"
            >
              <Stack direction="row" spacing={0} alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { lg: "none" } }}
                >
                  <AiOutlineMenuFold color="#0066ee" />
                </IconButton>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <headerTitle.icons
                    style={{ cursor: "pointer", marginRight: "12px" }}
                  />
                </Box>
                <Typography
                  variant="h2"
                  sx={{
                    color: "#00156a",
                    fontWeight: "900",
                    m: 0,
                    p: 0,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {headerTitle.title}
                </Typography>
              </Stack>
              <UserAction />
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              pt: 3,
            },
          }}
        >
          <Box sx={{ overflow: "auto", height: "100%" }}>
            <IconButton
              aria-label="close"
              onClick={handleDrawerToggle}
              sx={{
                position: "absolute",
                right: 3,
                top: 3,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoIosCloseCircleOutline />
            </IconButton>

            <a href={`${process.env.REACT_APP_WEB_URL}`}>
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Logo style={{ cursor: "pointer", maxWidth: "90px" }} />
              </Box>
            </a>
            <Divider />
            <Sidebar />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              paddingTop: "15px",
              background: "#F0F5FF",
              border: "none",
            },
          }}
          open
        >
          <Box sx={{ overflow: "auto", height: "100%" }}>
            <a href={`${process.env.REACT_APP_WEB_URL}`}>
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                <Logo style={{ cursor: "pointer", maxWidth: "120px" }} />
              </Box>
            </a>
            <Sidebar />
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          pt: { xs: 10, lg: 10, xl: 13 },
          background: "#FAFAFA",
          flexGrow: 1,
          minHeight: "100vh",
          pb: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ px: { xs: "1rem", lg: "2rem", xl: "4rem" } }}
        >
          {children}
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
