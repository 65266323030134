import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import DataTable from "../BiensEstimes/TableData";
import { formatDate } from "../../utils/utils";

export default function ProjetDachatSoumis({ purchaseProjects }) {
  const [projectDaChatData, setProjectDaChatData] = useState([]);

  const getProjetDachatSoumis = async () => {
    const newData = purchaseProjects.map((item, index) => {
      const adsData = item?.wishlist_id?.adsdata;
      const {
        creation_date,
        borrow_money,
        purchase_place,
        sell_real_estate,
        invest_real_estate,
        _id,
      } = item;
      const { address, area } = JSON.parse(adsData ?? "{}");

      const formattedDate = formatDate(creation_date).dateDMY;
      return {
        _id,
        formattedDate,
        address,
        area,
        borrow_money,
        purchase_place,
        sell_real_estate,
        invest_real_estate,
      };
    });
    setProjectDaChatData(newData);
  };

  useEffect(() => {
    getProjetDachatSoumis();
  }, [purchaseProjects]);

  const projectDeChatDataFields = [
    { field: "formattedDate", headerName: "Date", width: 150 },
    { field: "address", headerName: "Adresse", width: 150 },
    { field: "area", headerName: "Surface", width: 150 },
    { field: "borrow_money", headerName: "Propriétaire", width: 150 },
    { field: "purchase_place", headerName: "Type", width: 150 },
    { field: "sell_real_estate", headerName: "Statut", width: 150 },
    { field: "invest_real_estate", headerName: "Neuf", width: 100 },
  ];

  return (
    <Paper
      elevation={3}
      sx={{ p: 4, width: "100%", borderRadius: "13px", mt: 4 }}
    >
      <Typography variant="body2" sx={{ fontWeight: "600", mb: 3 }}>
        Projet d’achat soumis ({projectDaChatData?.length})
      </Typography>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <DataTable
          filteredData={projectDaChatData}
          keysData={projectDeChatDataFields}
        />
      </Paper>
    </Paper>
  );
}
