import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Alert,
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { GoCheckCircle } from "react-icons/go";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { GalleryIcon } from "../../../component/ConstantImagesURL";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../Redux/reducer/reducer";
import { generateImageHash } from "../../../utils/utils";
import watermark from "watermarkjs";
import { toast } from "react-toastify";
import ImageLoader from "./ImageLoader";
import { validateImage } from "./ImageValidation";
import { startDriverTour } from "../../../component/TourGuide";

const ImageTag = styled("div")(({ _ }) => ({
  position: "absolute",
  top: "86%",
  left: "58%",
  padding: "2px",
  width: "73%",
  transform: "translate(-50%, -50%)",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: `clamp(10px, 2vw, 12px)`,
  backgroundColor: "rgba(38, 50, 56, 0.75)",
  textWrap: "nowrap",
  borderRadius: "8px",
}));

export default function DragDropFile({
  formData,
  setFormData,
  handleClick,
  submitData,
  setRef,
  scrollToElement,
  user,
}) {
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  const [errorTerms, setErrorTerms] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    if (user?.email_verification === false) {
      const steps = [
        {
          element: ".drag_drop_images",
          popover: {
            title: "Télécharger des images",
            description:
              "Téléchargez des images de haute qualité de votre propriété pour attirer des acheteurs ou locataires potentiels. Déposez vos images dans la zone désignée ou cliquez pour parcourir. Assurez-vous que les images sont lumineuses et attrayantes pour maximiser la visibilité. Acceptez les termes et conditions pour continuer.",
            align: "center",
          },
        },
        {
          element: ".terms_and_conditions",
          popover: {
            title: "Termes et conditions",
            description:
              "Vous devez accepter les termes et conditions pour continuer à publier votre annonce immobilière. En acceptant, vous assurez la conformité avec les directives et politiques de notre plateforme.",
            align: "center",
          },
        },
        {
          element: ".stepthree-prv-btn",
          popover: {
            title: "Étape précédente",
            description:
              "Cliquez sur 'Retour' pour revenir à l’étape précédente.",
            align: "center",
          },
        },
        {
          element: ".stepthree-nxt-btn",
          popover: {
            title: "Publier l'annonce",
            description:
              "Cliquez sur 'Publier' pour finaliser et soumettre votre annonce.",
            align: "center",
          },
        },
      ];
      startDriverTour(steps, "step-three", "publishAds-steps-completed");
    }
  }, [user]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      handleFiles(droppedFiles);
      setError("");
      setErrorTerms("");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      handleFiles(selectedFiles);
      setError("");
      setErrorTerms("");
      e.target.value = null;
    }
  };

  const getUserData = (event) => {
    const { checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      termsAndConditions: checked,
    }));
    checked
      ? setErrorTerms("")
      : setErrorTerms("Les termes et conditions doivent être acceptés");
  };
  const handleFiles = async (newFiles) => {
    setImgLoading(true);
    setUploadProgress(0);
    const s3_images_url = formData.s3_images_url || [];
    const imageHashes = [...(formData.imagehash || [])];
    const watermarkImageUrl = "/ki-watermark.svg";
    let duplicateCount = 0;
    const totalFiles = newFiles.length;
    let processedFiles = 0;
    // Track suspicious images
    let suspiciousImages = formData.suspiciousImgArray || [];
    for (const newFile of newFiles) {
      const isDuplicate = s3_images_url.some(
        (file) => file.name === newFile.name
      );
      if (isDuplicate) {
        duplicateCount++;
        processedFiles++;
        setUploadProgress((processedFiles / totalFiles) * 100);
        continue;
      }
      try {
        // Validate image before processing
        const validationResult = await validateImage(newFile);
        // Process the image regardless of validation result
        const watermarkedImage = await applyWatermarkImage(
          newFile,
          watermarkImageUrl
        );
        s3_images_url.forEach((file) => URL.revokeObjectURL(file.previewUrl));
        const previewUrl = URL.createObjectURL(watermarkedImage);
        watermarkedImage.previewUrl = previewUrl;
        // Add validation result to the image object
        watermarkedImage.isValid = validationResult.isValid;
        watermarkedImage.tags = validationResult.tags;
        // If image is not valid, add it to suspicious images
        if (!validationResult.isValid) {
          suspiciousImages = [
            ...suspiciousImages,
            {
              name: newFile.name,
              reason: "No relevant real estate features detected",
            },
          ];
        }
        s3_images_url.push(watermarkedImage);
        const hash = await generateImageHash(watermarkedImage);
        imageHashes.push(hash);
        processedFiles++;
        setUploadProgress((processedFiles / totalFiles) * 100);
      } catch (error) {
        setImgLoading(false);
        setError("Error processing image");
        toast.error("Image Processing Error:", error);
        return;
      }
    }
    if (duplicateCount > 0) {
      toast.error(
        `${duplicateCount} images en double ont été trouvées, veuillez choisir d'autres images !`
      );
    }
    // Update form data with suspicious images only if there are any
    const updatedFormData = {
      ...formData,
      s3_images_url,
      imagehash: imageHashes,
      // suspiciousImgArray: suspiciousImages,
      suspiciousImg: suspiciousImages.length > 0,
    };
    setFormData(updatedFormData);
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setError("");
    setErrorTerms("");
    setImgLoading(false);
    setTimeout(() => {
      setUploadProgress(0);
    }, 1000);
  };

  const removeFile = (e, file, index) => {
    e.preventDefault();
    const s3_images_url = formData.s3_images_url || [];
    const imagehash = formData.imagehash || [];
    const suspiciousImgArray = formData.suspiciousImgArray || [];
    let updatedImages = [];
    let updatedHashes = [...imagehash];
    let updatedSuspiciousImages = [...suspiciousImgArray];
    if (typeof file === "object" && file.name) {
      // Case 1: Newly uploaded file (File object)
      updatedImages = s3_images_url.filter((f) => f.name !== file.name);
      updatedHashes = imagehash.filter((_, idx) => idx !== index);  // Remove corresponding hash
      updatedSuspiciousImages = updatedSuspiciousImages.filter((img) => img.name !== file.name);
    } else if (typeof file === "string") {
      // Case 2: Previously uploaded image (URL string)
      updatedImages = s3_images_url.filter((f) => f !== file);  // Remove URL
      updatedHashes = imagehash.filter((_, idx) => idx !== index);  // Remove corresponding hash
      updatedSuspiciousImages = updatedSuspiciousImages.filter((img) => img !== file);
    }
    // Create updated form data object
    const updatedFormData = {
      ...formData,
      s3_images_url: updatedImages,
      imagehash: updatedHashes,
      suspiciousImgArray: updatedSuspiciousImages,
      suspiciousImg: updatedSuspiciousImages.length > 0,
    };
    // Update state
    setFormData(updatedFormData);
    setError("");
    setErrorTerms("");
    // Clear file input if needed
    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };



  const handleBack = () => {
    handleClick("prev");
  };

  const handleFinish = async () => {
    if (!formData.s3_images_url || formData.s3_images_url.length === 0) {
      setError("Veuillez ajouter au moins une image");
    } else if (
      formData?.termsAndConditions === false ||
      !formData?.termsAndConditions
    ) {
      setErrorTerms("Les termes et conditions doivent être acceptés");
      scrollToElement("termsAndConditions");
    } else {
      setLoading(true); // Set loading to true
      setError("");
      setErrorTerms("");
      setLoading(true);
      try {
        await submitData();
      } catch (error) {
        setError("Erreur lors de la soumission des données");
      } finally {
        setLoading(false);
      }
    }
  };

  const applyWatermarkImage = async (file, watermarkUrl) => {
    const dataUrl = await new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = rej;
      reader.readAsDataURL(file);
    });
    const watermarkedUrl = await watermark([dataUrl, watermarkUrl])
      .image(watermark.image.center(0.6))
      .then((img) => img.src);
    const blob = await (await fetch(watermarkedUrl)).blob();
    return new File([blob], file.name, { type: file.type });
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          gap: 5,
          margin: "32px 0 110px 0",
        }}
        className="stepTwo"
      >
        <Box
          className="drag_drop_images"
          id="form-file-upload"
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <Box>
              <img src={GalleryIcon} alt="Gallery Icon" />
              <Typography mt={2}>
                Déposez votre image ici, ou{" "}
                <span
                  style={{ fontSize: 18, fontWeight: 500, color: "#0066EE" }}
                >
                  parcourez
                </span>
              </Typography>
              {imgLoading && (
                <ImageLoader value={uploadProgress} style={{ marginTop: 5 }} />
              )}
              <Typography mt={3} fontSize={"12px"}>
                Supportent les formats JPG, JPEG2000, PNG, WebP...
              </Typography>
              <Typography fontSize={"12px"}>
                (Limite de 30Mo / Photo)
              </Typography>
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              pt={6}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {formData.s3_images_url &&
                formData.s3_images_url.map((file, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={2.4}
                    sx={{ position: "relative" }}
                    key={index}
                  >
                    <img
                      src={
                        typeof file === "object"
                          ? URL.createObjectURL(file)
                          : `https://content.1ki.ma/${file}`
                      }
                      alt={`preview ${index}`}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "14px",
                      }}
                      loading="lazy"
                    />

                    {index === 0 && <ImageTag>Image principale</ImageTag>}
                    <Box
                      style={{
                        position: "absolute",
                        top: "14px",
                        right: "2px",
                        cursor: "pointer",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        width: "18px",
                        height: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoClose
                        color="#FFFFFF"
                        size={"14px"}
                        onClick={(e) => removeFile(e, file, index)}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </label>
          {dragActive && (
            <Box
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </Box>
        {error !== "" && (
          <Typography textAlign={"left"} color={"#EC544B"} my={1}>
            {error}
          </Typography>
        )}

        <Alert
          severity="info"
          py={0}
          style={{
            marginTop: "16px",
            backgroundColor: "#DCECFE",
            color: "#0066EE",
            fontSize: 14,
            height: 40,

            fontWeight: 500,
            borderRadius: "8px",
            alignItems: "center",
            padding: "2px 8px",
          }}
          iconMapping={{
            info: <HiOutlineInformationCircle color={"#0066EE"} size={25} />,
          }}
        >
          Réorganisez les photos pour modifier la couverture
        </Alert>
        <Alert
          severity="info"
          style={{
            marginTop: "20px",
            backgroundColor: "transparent",
            color: "#263238",
            fontSize: 14,
            fontWeight: 400,
            borderRadius: "8px",
            padding: 0,
          }}
          iconMapping={{
            info: <GoCheckCircle color={"#5EC38D"} size={22} />,
          }}
        >
          Pour plus de visibilité choisissez comme photo principale une belle
          photo du séjour ou la façade de la maison.
        </Alert>
        <Alert
          severity="info"
          style={{
            backgroundColor: "transparent",
            color: "#263238",
            fontSize: 14,
            fontWeight: 400,
            borderRadius: "8px",
            padding: 0,
            paddingTop: "8px",
          }}
          iconMapping={{
            info: <MdDoNotDisturbAlt color={"#EC544B"} size={22} />,
          }}
        >
          Les photos sombres (lumière éteinte, rideaux fermés...) n’attirent pas
          les visiteurs.
        </Alert>
        <FormControlLabel
          className="terms_and_conditions"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "15px",
              color: "#263238",
              marginLeft: "4px",
              marginTop: "8px",
            },
            display: "flex",
            alignItems: "center",
          }}
          control={
            <Checkbox
              ref={setRef("termsAndConditions")}
              checked={formData.termsAndConditions || false}
              onChange={getUserData}
              sx={{
                "& .MuiSvgIcon-root": {
                  height: "20px",
                  width: "20px",
                  fontSize: 20,
                  marginTop: "8px",
                  color: "#0066EE",
                  marginLeft: "5px",
                  borderRadius: "20px",
                  padding: "0px !important",
                },
              }}
              name="terms_and_conditions"
            />
          }
          label={
            <Typography sx={{ m: "9.3px 0px 0px", fontSize: "14px" }}>
              En postant une annonce sur notre plateforme, vous acceptez nos{" "}
              <a
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_WEB_URL +
                    "/mes-annonces-termes-et-conditions"
                  )
                }
              >
                <b>conditions générales.</b>
              </a>
            </Typography>
          }
        />
        {errorTerms !== "" && (
          <Typography textAlign={"left"} color={"#EC544B"} ml={0.5}>
            {errorTerms}
          </Typography>
        )}
      </Container>
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Button
            className="stepthree-prv-btn"
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleBack}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            className="stepthree-nxt-btn"
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleFinish}
            disabled={loading}
          >
            Publier
          </Button>
        </Box>
      </Box>
    </>
  );
}
