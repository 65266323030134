import React, { useEffect, useState } from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function Filter({
  filterWishlist,
  setWishlistData,
  selectedStatus,
  setSelectedStatus,
  resetPagination,
}) {
  const [statusCounts, setStatusCounts] = useState({});

  useEffect(() => {
    const counts = {};
    let totalCount = 0;

    filterWishlist?.forEach((item) => {
      counts[item.status] = (counts[item.status] || 0) + 1;
      totalCount += 1;
    });

    counts["Toutes"] = totalCount;

    setStatusCounts(counts);
  }, [filterWishlist]);

  const handleChange = async (event, newAlignment) => {
    if (newAlignment !== null) {
      const status = newAlignment || "Toutes";
      const dataByStatus =
        status === "Toutes"
          ? filterWishlist
          : filterWishlist?.filter((obj) => obj.status === status);

      setWishlistData(dataByStatus);
      setSelectedStatus(status);
      resetPagination();
    }
  };

  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      value={selectedStatus}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        textWrap: "nowrap",
        textTransform: "inherit",
        "& .Mui-selected": {
          background: "#0066ee !important",
          color: "#ffffff !important",
        },
        "& .MuiToggleButton-root": {
          textTransform: "inherit",
        },
      }}
    >
      {[
        "Toutes",
        "À contacter",
        "Contacté",
        "À visiter",
        "Visité",
        "Dossier déposé",
      ].map((status) => (
        <ToggleButton key={status} value={status}>
          <Box> {status}&nbsp; </Box>
          <Box> ({statusCounts[status] || 0})</Box>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
